export const translations = {
  default: {},
  en: {
    urls: {
      search: '/en/search',
      newsList: '/en/news',
      newsListAll: '/en/news/all',
      eventList: '/en/events',
      eventListAll: '/en/events/all',
      blogList: '/en/blog',
      blogListAll: '/en/blog/all',
      historyList: '/en/history',
      doctorList: '/en/doctors',
    },
    translation: {
      '{{bytes}} Bytes': '{{bytes}} Bytes',
      '{{bytes}} GB': '{{bytes}} GB',
      '{{bytes}} KB': '{{bytes}} KB',
      '{{bytes}} MB': '{{bytes}} MB',
      '{{capacity}} cm<sup>3</sup>': '{{capacity}} cm<sup>3</sup>',
      '{{km}} km': '{{km}} km',
      '{{price}} CHF': '{{price}} CHF',
      '{{section}} Subnavigation': '{{section}} Subnavigation',
      '1st Circulation': '1st Circulation',
      '404 – Not found': '404 – Not found',
      'Page not found': 'Page not found',
      'About us': 'About us',
      'ac_paraplegie_ch.name': 'Active Communication',
      'ac_paraplegie_ch.text':
        'We provide people who suffer from disabilities or neurological disorders with electronic and didactic aids to allow them to enjoy increased activity and autonomy in their everyday lives.',
      'ac_paraplegie_ch.title': 'Active Communication',
      'Additional equipment': 'Additional equipment',
      All: 'All',
      'All companies from A-Z': 'All companies from A-Z',
      'All posts': 'All posts',
      April: 'April',
      Arrival: 'Arrival',
      August: 'August',
      Back: 'Back',
      'Back to {{domain}} Home': 'Back to {{domain}} Home',
      'Back to the frontpage.': 'Back to the frontpage',
      'Become a member': 'Become a member',
      'Mitglied werden': 'Become a member',
      Blog: 'Blog',
      'Book a room': 'Book a room',
      Brand: 'Brand',
      Capacity: 'Capacity',
      Categories: 'Categories',
      Category: 'Category',
      'Check availability': 'Check availability',
      CHF: 'CHF',
      'Choose donation amount': 'Choose donation amount',
      'Click here for the homepage.': 'Click here for the homepage.',
      'Close "{{section}}" Flyout': 'Close "{{section}}" Flyout',
      'Close Photogallery (ESC)': 'Close Photogallery (ESC)',
      'Close search': 'Close search',
      Color: 'Color',
      'Companies by industries': 'Companies by industries',
      'Companies by industry': 'Companies by industry',
      'Company name, place or industry': 'Company name, place or industry',
      Competences: 'Competences',
      Contact: 'Contact',
      'Contact {{domain}}': 'Contact {{domain}}',
      'Current weather': 'Current weather',
      'Custom amount': 'Custom amount',
      December: 'December',
      Delete: 'Delete',
      Departure: 'Departure',
      Details: 'Details',
      'Doctors at the SPC': 'Doctors at the SPC',
      Donate: 'Donate',
      'Donate now': 'Donate now',
      'Donate now - short': 'Donate now',
      'Download document': 'Download document',
      'Each <em>Donation</em> counts': 'Each <em>Donation</em> counts',
      Edit: 'Edit',
      Email: 'Email',
      Mail: 'Mail',
      'Event info': 'Event info',
      Events: 'Events',
      Fax: 'Fax',
      February: 'February',
      Field: 'Field',
      'Filter by organisation': 'Filter by organisation',
      'Follow Link': 'Follow link',
      Footer: 'Footer',
      'forschung_paraplegie_ch.name': 'Swiss Paraplegic Research',
      'forschung_paraplegie_ch.text':
        'Our research shows our commitment to improving quality of life and integrating people with spinal paralysis into society over the long term.',
      'forschung_paraplegie_ch.title': 'Swiss Paraplegic Research',
      'fst_paraplegie_ch.name': 'Innovation Centre for Assistive Technology',
      'fst_paraplegie_ch.text':
        'As a centre of expertise and knowledge factory, we are the point of contact for the development and enhancement of resources for people with spinal cord injuries.',
      'fst_paraplegie_ch.title': 'Innovation Centre for Assistive Technology',
      Function: 'Function',
      Glossary: 'Glossary',
      'gonner_vereinigung_paraplegie_ch.name': 'Benefactors Association',
      'gonner_vereinigung_paraplegie_ch.text':
        'The more than 1.8 million members of the Swiss Paraplegic Foundation Benefactors’ Association work together to ensure that the comprehensive network of services which benefits people with spinal cord injuries remains intact.',
      'gonner_vereinigung_paraplegie_ch.title': 'Benefactors Association',
      'Head of Departments': 'Head of Departments',
      History: 'History',
      'History overview': 'History overview',
      Home: 'Home',
      Inside: 'Inside',
      'Is current page': 'Is current page',
      January: 'January',
      July: 'July',
      June: 'June',
      KM: 'Km',
      Language: 'Language',
      'Latest news': 'Latest news',
      'Latest news overview': 'Latest news overview',
      'Learn more': 'Learn more',
      Links: 'Links',
      'Load more': 'Load more',
      'Mail {{mail}}': 'Mail {{mail}}',
      'Mail: {{mail}}': 'Mail: {{mail}}',
      'Main Treatment Areas': 'Treatment focal points',
      March: 'March',
      May: 'May',
      'Medical domain': 'Medical domain',
      Memberships: 'Membership in professional associations',
      Menu: 'Menu',
      MFK: 'MFK',
      Mobile: 'Mobile',
      Modification: 'Modification',
      Month: 'Month',
      More: 'More',
      'More...': 'More…',
      'Navigation area': 'Navigation area',
      News: 'News',
      Newsletter: 'Newsletter',
      'Newsletter Sign up': 'Newsletter Sign up',
      Next: 'Next',
      'Next page': 'Next page',
      'Next years': 'Next Years',
      'No matter the amount, you make an important contribution to paraplegics.':
        'No matter how big or small, you are making an important contribution to the lives of people with spinal cord injuries.',
      November: 'November',
      October: 'October',
      'Oops, this page does not exist.<br />Would you like to help paraplegics?<br />Click on a heart.':
        'Oops, this page does not exist.<br />Would you like to help paraplegics?<br />Click on a heart.',
      'Open image in fullscreen': 'Open image in fullscreen',
      'Open navigation': 'Open navigation',
      'Open Search': 'Open Search',
      Options: 'Options',
      Organisation: 'Organisation',
      Organisations: 'Organisations',
      'orthotec_paraplegie_ch.name': 'Orthotec',
      'orthotec_paraplegie_ch.text':
        'Wir bieten massgeschneiderte Lösungen um die grösstmögliche Mobilität von Querschnittgelähmten zu gewährleisten.',
      'orthotec_paraplegie_ch.title': 'Orthotec',
      'orthotecsports_paraplegie_ch.name': 'Orthotecsports',
      'orthotecsports_paraplegie_ch.title': 'Orthotecsports',
      Page: 'Page',
      'parahelp_paraplegie_ch.name': 'Parahelp',
      'parahelp_paraplegie_ch.title': 'ParaHelp',
      'Paraplegie.ch blog overview': 'Paraplegie.ch blog overview',
      'Paraplegie.ch doctors overview': 'Paraplegie.ch doctors overview',
      'Paraplegie.ch events overview': 'Paraplegie.ch events overview',
      'paraplegie_ch.name': 'Paraplegie Main Area',
      'paraplegie_ch.title': 'Swiss Paraplegic Group',
      Phone: 'Telephone',
      'Phone number: {{phone}}': 'Phone number: {{phone}}',
      'Please input some keywords': 'Please input some keywords.',
      'Please use other keywords for searching.':
        'Please use other keywords for searching.',
      Previous: 'Previous',
      'Previous page': 'Previous page',
      'Previous years': 'Previous Years',
      Price: 'Price',
      'Price new': 'Price new',
      'Professional Career': 'Professional background',
      Print: 'Print',
      PS: 'PS',
      Publications: 'Publications',
      Published: 'Published',
      Read: 'Read',
      'Read more': 'Read more',
      'Related posts': 'Related posts',
      'Request an offer': 'Request an offer',
      'Scroll down': 'Scroll down',
      Search: 'Search',
      'Search company by name, industry or place':
        'Search company by name, industry or place',
      'Search for <strong>{{term}}</strong>':
        'Search for <strong>{{term}}</strong>',
      'Search on Paraplegie.ch': 'Search on paraplegie.ch',
      'Search term': 'Search term',
      Selected: 'Selected',
      'Senior Consultants': 'Senior Consultants',
      September: 'September',
      Services: 'Services',
      Share: 'Share',
      '--Show all--': '--Show all--',
      'Show CV': 'Show CV',
      'Show next image': 'Show next image',
      'Show previous image': 'Show previous image',
      'sirmed_paraplegie_ch.name': 'Sirmed',
      'sirmed_paraplegie_ch.text':
        'Rehabilitation starts at the scene of the accident. We are ready to help emergency patients – especially people with spinal paralysis – to receive the best possible initial care.',
      'sirmed_paraplegie_ch.title': 'Sirmed',
      'Slide {{slide}}': 'Slide {{slide}}',
      'Specialty Registrars': 'Specialty Registrars',
      'Specialist titles and specialisations':
        'Specialist titles and specialisations',
      Specialist: 'Specialist',
      'Specialist title': 'Specialist medical qualification',
      'sportmedizin_paraplegie_ch.name': 'Sports Medicine Nottwil',
      'sportmedizin_paraplegie_ch.text':
        'Our sports medics are glad to help you on your journey to achieve your next sporting goal.',
      'sportmedizin_paraplegie_ch.title': 'Sports Medicine Nottwil',
      'stiftung_paraplegie_ch.name': 'Swiss Paraplegic Group',
      'stiftung_paraplegie_ch.text':
        'We support people with spinal cord injuries. Throughout their lives.',
      'stiftung_paraplegie_ch.title': 'Swiss Paraplegic Foundation',
      Suche: 'Search',
      'Support one of our heart projects for paraplegics':
        'Support one of our heart projects for paraplegics',
      'Paraplegiker-Gruppe': 'Paraplegic Group',
      'Swiss Paraplegic Group': 'Swiss Paraplegic Group',
      'Swiss Paraplegic Group on Facebook':
        'Swiss Paraplegic Group on Social Media',
      'Telephone {{phone}}': 'Telephone {{phone}}',
      'There are no blog posts that match your filters.':
        'There are no blog posts that match your filters.',
      'There are no companies that match your filters.':
        'There are no companies that match your filters.',
      'There are no doctors that match your filters.':
        'There are no doctors that match your filters.',
      'There are no events that match your filters.':
        'There are no events that match your filters.',
      'There are no medical offers that match your filters.':
        'There are no medical offers that match your filters.',
      'There are no news that match your filters.':
        'There are no news that match your filters.',
      'To top': 'To top',
      Toggle: 'Toggle',
      Translate: 'Translate',
      'Training and Education': 'Training and further education',
      'Unfortunately we could not find any content for this address. Perhaps you followed an old or incorrect link. Please check the address again.':
        'Unfortunately we could not find any content for this address. Perhaps you followed an old or incorrect link. Please check the address again.',
      'vereinigung_paraplegie_ch.name': 'Swiss Paraplegic Association',
      'vereinigung_paraplegie_ch.text':
        'As the national self-help and professional organisation for people with spinal cord injuries, we are committed to meeting the needs of para- and tetraplegics throughout Switzerland.',
      'vereinigung_paraplegie_ch.title': 'Swiss Paraplegic Association',
      Weather: 'Weather',
      'Weather forecast for the next three days':
        'Weather forecast for the next three days',
      'Website {{website}}': 'Website {{website}}',
      Year: 'Year',
      Tomorrow: 'Tomorrow',
      Morgen: 'Tomorrow',
      Demain: 'Tomorrow',
      Domani: 'Tomorrow',
      Monday: 'Monday',
      Montag: 'Monday',
      Lundi: 'Monday',
      Lunedi: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Mittwoch: 'Wednesday',
      Mercredi: 'Wednesday',
      Mercoledi: 'Wednesday',
      Thursday: 'Thursday',
      Donnerstag: 'Thursday',
      Jeudi: 'Thursday',
      Giovedi: 'Thursday',
      Friday: 'Friday',
      Freitag: 'Friday',
      Vendredi: 'Friday',
      Venerdi: 'Friday',
      Saturday: 'Saturday',
      Samstag: 'Saturday',
      Samedi: 'Saturday',
      Sabato: 'Saturday',
      Sunday: 'Sunday',
      Sonntag: 'Sunday',
      Dimanche: 'Sunday',
      Domenica: 'Sunday',
      'You are here': 'You are here',
      'Your search returned <strong>{{results}} results</strong>.':
        'Your search returned <strong>{{results}} results</strong>.',
      'zentrum_paraplegie_ch.name': 'Swiss Paraplegic Centre',
      'zentrum_paraplegie_ch.text':
        'As a special clinic for paraplegics, our focus is on holistic rehabilitation and comprehensive care for people with spinal paralysis.',
      'zentrum_paraplegie_ch.title': 'Swiss Paraplegic Centre',
      Show: 'Show',
      'Future events': 'Future events',
      'Past events': 'Past events',
      'Overview of all news': 'Overview of all news',
      'Overview of all events': 'Overview of all events',
      'View on Google Maps': 'View on Google Maps',
      Login: 'Login',
      Username: 'Username',
      Password: 'Password',
      Submit: 'Submit',
      'Sign up for the Paraplegic Foundation newsletter':
        'Sign up for the Paraplegic Foundation newsletter',
      'Email address': 'Email address',
      Subscribe: 'Subscribe',
      Gender: 'Gender',
      Male: 'Male',
      Female: 'Female',
      'Last name': 'Last name',
      'First name': 'First name',
      Interest: 'Interest',
      'Subscribe to our newsletter': 'Subscribe to our newsletter',
      'Total cost': 'Total cost',
      'Personal data': 'Personal data',
      Surname: 'Surname',
      Company: 'Company',
      Street: 'Street',
      'Street number': 'Street number',
      'P.O. Box': 'P.O. Box',
      Postcode: 'Postcode',
      'Town/City': 'Town/City',
      Country: 'Country',
      'Proceed to payment': 'Proceed to payment',
      'Your address': 'Your address',
      'Ms.': 'Ms.',
      'Mr.': 'Mr.',
      'I would like the Centre for Pain Medicine to keep me informed about conferences and courses.':
        'I would like the Centre for Pain Medicine to keep me informed about conferences and courses.',
      'I would like to receive a payment confirmation for my employer.':
        'I would like to receive a payment confirmation for my employer.',
      Switzerland: 'Switzerland',
      Germany: 'Germany',
      France: 'France',
      Italy: 'Italy',
      Afghanistan: 'Afghanistan',
      Albania: 'Albania',
      Algeria: 'Algeria',
      'American Samoa': 'American Samoa',
      Andorra: 'Andorra',
      Angola: 'Angola',
      Anguilla: 'Anguilla',
      Antarctica: 'Antarctica',
      'Antigua and Barbuda': 'Antigua and Barbuda',
      Argentina: 'Argentina',
      Armenia: 'Armenia',
      Aruba: 'Aruba',
      Australia: 'Australia',
      Austria: 'Austria',
      Azerbaijan: 'Azerbaijan',
      Bahamas: 'Bahamas',
      Bahrain: 'Bahrain',
      Bangladesh: 'Bangladesh',
      Barbados: 'Barbados',
      Belarus: 'Belarus',
      Belgium: 'Belgium',
      Belize: 'Belize',
      Benin: 'Benin',
      Bermuda: 'Bermuda',
      Bhutan: 'Bhutan',
      Bolivia: 'Bolivia',
      'Bosnia and Herzegovina': 'Bosnia and Herzegovina',
      Botswana: 'Botswana',
      'Bouvet Island': 'Bouvet Island',
      Brazil: 'Brazil',
      'British Indian Ocean Territory': 'British Indian Ocean Territory',
      'Brunei Darussalam': 'Brunei Darussalam',
      Bulgaria: 'Bulgaria',
      'Burkina Faso': 'Burkina Faso',
      Burundi: 'Burundi',
      Cambodia: 'Cambodia',
      Cameroon: 'Cameroon',
      Canada: 'Canada',
      'Cape Verde': 'Cape Verde',
      'Cayman Islands': 'Cayman Islands',
      'Central African Republic': 'Central African Republic',
      Chad: 'Chad',
      Chile: 'Chile',
      China: 'China',
      'Christmas Island': 'Christmas Island',
      'Cocos (Keeling) Islands': 'Cocos (Keeling) Islands',
      Colombia: 'Colombia',
      Comoros: 'Comoros',
      'Congo (Brazzaville)': 'Congo (Brazzaville)',
      'Congo (Kinshasa)': 'Congo (Kinshasa)',
      'Cook Islands': 'Cook Islands',
      'Costa Rica': 'Costa Rica',
      Croatia: 'Croatia',
      Cuba: 'Cuba',
      Cyprus: 'Cyprus',
      'Czech Republic': 'Czech Republic',
      'Côte dIvoire': 'Côte dIvoire',
      Denmark: 'Denmark',
      Djibouti: 'Djibouti',
      Dominica: 'Dominica',
      'Dominican Republic': 'Dominican Republic',
      Ecuador: 'Ecuador',
      Egypt: 'Egypt',
      'El Salvador': 'El Salvador',
      'Equatorial Guinea': 'Equatorial Guinea',
      Eritrea: 'Eritrea',
      Estonia: 'Estonia',
      Ethiopia: 'Ethiopia',
      'Falkland Islands': 'Falkland Islands',
      'Faroe Islands': 'Faroe Islands',
      Fiji: 'Fiji',
      Finland: 'Finland',
      'French Guiana': 'French Guiana',
      'French Polynesia': 'French Polynesia',
      'French Southern Lands': 'French Southern Lands',
      Gabon: 'Gabon',
      Gambia: 'Gambia',
      Georgia: 'Georgia',
      Ghana: 'Ghana',
      Gibraltar: 'Gibraltar',
      Greece: 'Greece',
      Greenland: 'Greenland',
      Grenada: 'Grenada',
      Guadeloupe: 'Guadeloupe',
      Guam: 'Guam',
      Guatemala: 'Guatemala',
      Guernsey: 'Guernsey',
      Guinea: 'Guinea',
      'Guinea-Bissau': 'Guinea-Bissau',
      Guyana: 'Guyana',
      Haiti: 'Haiti',
      'Heard and McDonald Islands': 'Heard and McDonald Islands',
      Honduras: 'Honduras',
      'Hong Kong': 'Hong Kong',
      Hungary: 'Hungary',
      Iceland: 'Iceland',
      India: 'India',
      Indonesia: 'Indonesia',
      Iran: 'Iran',
      Iraq: 'Iraq',
      Ireland: 'Ireland',
      'Isle of Man': 'Isle of Man',
      Israel: 'Israel',
      Jamaica: 'Jamaica',
      Japan: 'Japan',
      Jersey: 'Jersey',
      Jordan: 'Jordan',
      Kazakhstan: 'Kazakhstan',
      Kenya: 'Kenya',
      Kiribati: 'Kiribati',
      'Korea, North': 'Korea, North',
      'Korea, South': 'Korea, South',
      Kosovo: 'Kosovo',
      Kuwait: 'Kuwait',
      Kyrgyzstan: 'Kyrgyzstan',
      Laos: 'Laos',
      Latvia: 'Latvia',
      Lebanon: 'Lebanon',
      Lesotho: 'Lesotho',
      Liberia: 'Liberia',
      Libya: 'Libya',
      Liechtenstein: 'Liechtenstein',
      Lithuania: 'Lithuania',
      Luxembourg: 'Luxembourg',
      Macau: 'Macau',
      Macedonia: 'Macedonia',
      Madagascar: 'Madagascar',
      Malawi: 'Malawi',
      Malaysia: 'Malaysia',
      Maldives: 'Maldives',
      Mali: 'Mali',
      Malta: 'Malta',
      'Marshall Islands': 'Marshall Islands',
      Martinique: 'Martinique',
      Mauritania: 'Mauritania',
      Mauritius: 'Mauritius',
      Mayotte: 'Mayotte',
      Mexico: 'Mexico',
      Micronesia: 'Micronesia',
      Moldova: 'Moldova',
      Monaco: 'Monaco',
      Mongolia: 'Mongolia',
      Montenegro: 'Montenegro',
      Montserrat: 'Montserrat',
      Morocco: 'Morocco',
      Mozambique: 'Mozambique',
      Myanmar: 'Myanmar',
      Namibia: 'Namibia',
      Nauru: 'Nauru',
      Nepal: 'Nepal',
      Netherlands: 'Netherlands',
      'Netherlands Antilles': 'Netherlands Antilles',
      'New Caledonia': 'New Caledonia',
      'New Zealand': 'New Zealand',
      Nicaragua: 'Nicaragua',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Niue: 'Niue',
      'Norfolk Island': 'Norfolk Island',
      'Northern Mariana Islands': 'Northern Mariana Islands',
      Norway: 'Norway',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Palau: 'Palau',
      Palestine: 'Palestine',
      Panama: 'Panama',
      'Papua New Guinea': 'Papua New Guinea',
      Paraguay: 'Paraguay',
      Peru: 'Peru',
      Philippines: 'Philippines',
      Pitcairn: 'Pitcairn',
      Poland: 'Poland',
      Portugal: 'Portugal',
      'Puerto Rico': 'Puerto Rico',
      Qatar: 'Qatar',
      Reunion: 'Reunion',
      Romania: 'Romania',
      'Russian Federation': 'Russian Federation',
      Rwanda: 'Rwanda',
      'Saint Helena': 'Saint Helena',
      'Saint Kitts and Nevis': 'Saint Kitts and Nevis',
      'Saint Lucia': 'Saint Lucia',
      'Saint Pierre and Miquelon': 'Saint Pierre and Miquelon',
      'Saint Vincent and the Grenadines': 'Saint Vincent and the Grenadines',
      'Saint-Barthélemy': 'Saint-Barthélemy',
      'Saint-Martin (French part)': 'Saint-Martin (French part)',
      Samoa: 'Samoa',
      'San Marino': 'San Marino',
      'Sao Tome and Principe': 'Sao Tome and Principe',
      'Saudi Arabia': 'Saudi Arabia',
      Senegal: 'Senegal',
      Serbia: 'Serbia',
      Seychelles: 'Seychelles',
      'Sierra Leone': 'Sierra Leone',
      Singapore: 'Singapore',
      Slovakia: 'Slovakia',
      Slovenia: 'Slovenia',
      'Solomon Islands': 'Solomon Islands',
      Somalia: 'Somalia',
      'South Africa': 'South Africa',
      'South Georgia and South Sandwich Islands':
        'South Georgia and South Sandwich Islands',
      Spain: 'Spain',
      'Sri Lanka': 'Sri Lanka',
      Sudan: 'Sudan',
      Suriname: 'Suriname',
      'Svalbard and Jan Mayen Islands': 'Svalbard and Jan Mayen Islands',
      Swaziland: 'Swaziland',
      Sweden: 'Sweden',
      Syria: 'Syria',
      Taiwan: 'Taiwan',
      Tajikistan: 'Tajikistan',
      Tanzania: 'Tanzania',
      Thailand: 'Thailand',
      'Timor-Leste': 'Timor-Leste',
      Togo: 'Togo',
      Tokelau: 'Tokelau',
      Tonga: 'Tonga',
      'Trinidad and Tobago': 'Trinidad and Tobago',
      Tunisia: 'Tunisia',
      Turkey: 'Turkey',
      Turkmenistan: 'Turkmenistan',
      'Turks and Caicos Islands': 'Turks and Caicos Islands',
      Tuvalu: 'Tuvalu',
      Uganda: 'Uganda',
      Ukraine: 'Ukraine',
      'United Arab Emirates': 'United Arab Emirates',
      'United Kingdom': 'United Kingdom',
      'United States Minor Outlying Islands':
        'United States Minor Outlying Islands',
      'United States of America': 'United States of America',
      Uruguay: 'Uruguay',
      Uzbekistan: 'Uzbekistan',
      Vanuatu: 'Vanuatu',
      'Vatican City': 'Vatican City',
      Venezuela: 'Venezuela',
      Vietnam: 'Vietnam',
      'Virgin Islands, British': 'Virgin Islands, British',
      'Virgin Islands, U.S.': 'Virgin Islands, U.S.',
      'Wallis and Futuna Islands': 'Wallis and Futuna Islands',
      'Western Sahara': 'Western Sahara',
      Yemen: 'Yemen',
      Zambia: 'Zambia',
      Zimbabwe: 'Zimbabwe',
      Åland: 'Åland',
      'Edit your booking': 'Edit your booking',
      'At least one price must be selected.':
        'At least one price must be selected.',
      'Salutation can not be blank.': 'Salutation can not be blank.',
      'First name can not be blank.': 'First name can not be blank.',
      'Last name can not be blank.': 'Last name can not be blank.',
      'Email can not be blank.': 'Email can not be blank.',
      'Email address is not valid.': 'Email address is not valid.',
      'Clinic and department can not be blank.':
        'Clinic and department can not be blank.',
      'Street can not be blank.': 'Street can not be blank.',
      'Postcode can not be blank.': 'Postcode can not be blank.',
      'Postcode must be numeric.': 'Postcode must be numeric.',
      'Postcode must greater than 4 digits.':
        'Postcode must greater than 4 digits.',
      'Town/City can not be blank.': 'Town/City can not be blank.',
      'Country can not be blank.': 'Country can not be blank.',
      'Latest blogs': 'Latest blogs',
      'Clinic and department': 'Clinic and department',
      'Your course selection': 'Your course selection',
      Title: 'Title',
      'I accept the General Terms and Conditions':
        'I accept the General Terms and Conditions',
      'You will find our General Terms and Conditions here':
        'You will find our General Terms and Conditions here',
      'You must accept the General Terms and Conditions':
        'You must accept the General Terms and Conditions',
      Membership: 'Membership',
      'Individual member': 'Individual member',
      'Married couples and families': 'Married couples and families',
      'Permanent member': 'Permanent member',
      'Become a member now': 'Become a member now',
      'per year': 'per year',
      'One-time': 'One-time',
      'Contact us': 'Contact us',
      'Quick Links': 'Quick Links',
      'Main navigation': 'Main navigation',
      'Member Service': 'Member Service',
      'A part of': 'A part of',
      'We offer': 'We offer',
      'Job Type': 'Job Type',
      'Donations for people with spinal cord injuries':
        'Donations for people with spinal cord injuries',
      'Your transaction is secure': 'Your transaction is secure',
      'Each Donation counts': 'Each Donation counts',
      '<em>Become</em> a member': 'Become a <em>member</em>',
      'And receive 250,000 francs in an emergency.':
        'As a member, you receive 250,000 francs if you sustain a spinal cord injury in an accident.',
      'Couples and families': 'Couples and families',
      once: 'one-off',
      'CHF 250,000 if the unthinkable happens':
        '250,000 francs if the unthinkable happens',
      'Protect yourself - become a member now':
        'Protect yourself - become a member now',
      '1.9 million members have already put their trust in the Swiss Paraplegic Foundation.':
        '2 million members have already put their trust in the Swiss Paraplegic Foundation.',
      '<em>Give the gift</em> of membership':
        '<em>Give the gift</em> of membership',
      'Your loved ones will receive 250,000 francs should the unthinkable happen.':
        'Your loved ones will receive 250,000 francs should the unthinkable happen.',
      'Gift now': 'Give a gift now',
      'A useful gift for your loved ones': 'A useful gift for your loved ones',
      'Giving a membership will provide joy and show you care.':
        'Giving a membership will provide joy and show you care.',
      'blocks.footer_contact': `
        <div class="FooterAddress">
          <div class="vcard">Swiss Paraplegic Centre<br />
              Guido A. Zäch Strasse 1<br />
              6207 Nottwil<br />
              <a href="mailto:spz@paraplegie.ch">spz@paraplegie.ch</a><br />
              <a href="tel:+41419395454">T.&nbsp;+41 41 939 54 54</a>
          </div>
          <br />
          <div class="vcard">Swiss Paraplegic Foundation<br />
            Benefactors Association<br />
            Guido A. Zäch Strasse 6<br />
            6207 Nottwil<br />
            <a href="mailto:sps@paraplegie.ch">sps@paraplegie.ch</a><br />
            <a href="tel:+41419396262">T.&nbsp;+41 41 939 62 62</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.sportmedizin_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Sports Medicine Nottwil<br />
            Guido A. Zäch Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:sportmedizin@sportmedizin-nottwil.ch">sportmedizin@sportmedizin-nottwil.ch</a><br />
            <a href="tel:+41419396600">T.&nbsp;+41 41 939 66 00</a>
          </div>
          <br />
          <div class="vcard">
            <b>Registration</b><br />
            Monday to Thursday<br />
            08.00 - 12.30 and 13.30 - 17.00<br />
            Friday<br />
            08.00 - 12.30 and 13.30 - 16.00
          </div>
        </div>
      `,
      'blocks.footer_contact.sirmed_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">SIRMED<br />
            Swiss Institute of Emergency Medicine<br />
            Guido A. Zäch Strasse 2b<br />
            6207 Nottwil<br />
            <a href="mailto:info@sirmed.ch">info@sirmed.ch</a><br />
            <a href="tel:+41419395050">T.&nbsp;+41 41 939 50 50</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.activecommunication_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Active Communication<br />
            Sumpfstrasse 28<br />
            6312 Steinhausen<br />
            <a href="mailto:info@activecommunication">info@activecommunication.ch</a><br />
            <a href="tel:+41417470303">T.&nbsp;+41 41 747 03 03</a><br />
            <a href="tel:+41417470300">T.&nbsp;+41 41 747 03 00 (Service-Center D)</a><br />
            <a href="tel:+41417470312">T.&nbsp;+41 41 747 03 12 (Service-Center F)</a><br />
          </div>
        </div>
      `,
      'blocks.footer_contact.gonner_vereinigung_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Gönner-Vereinigung der Schweizer Paraplegiker-Stiftung<br />
            Guido A. Zäch Strasse 10<br />
            6207 Nottwil<br />
            <a href="mailto:sps@paraplegie.ch">sps@paraplegie.ch</a><br />
            <a href="tel:+41419396262">T.&nbsp;+41 41 939 62 62</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.forschung_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Swiss Paraplegic Research<br />
            Guido A. Zäch-Strasse 4<br />
            6207 Nottwil<br />
            <a href="mailto:spf@paraplegie.ch">spf@paraplegie.ch</a><br />
            <a href="tel:+41419396565">T.&nbsp;+41 41 939 65 65</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.orthotec_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Orthotec AG<br />
            Guido A. Zäch Strasse 1<br />
            6207 Nottwil <br />
            <a href="mailto:info@orthotec.ch">info@orthotec.ch</a><br />
            <a href="tel:+41419395606">T.&nbsp;+41 41 939 56 06</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.orthotecsports_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Orthotec AG<br />
            Guido A. Zäch Strasse 1<br />
            6207 Nottwil <br />
            <a href="mailto:info@orthotec.ch">info@orthotec.ch</a><br />
            <a href="tel:+41419395606">T.&nbsp;+41 41 939 56 06</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.stiftung_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Swiss Paraplegic Foundation<br />
            Guido A. Zäch Strasse 10<br />
            6207 Nottwil<br />
            <a href="mailto:sps.sec@paraplegie.ch">sps.sec@paraplegie.ch</a><br />
            <a href="tel:+41419396363">T.&nbsp;+41 41 939 63 63</a>

          </div>
        </div>
      `,
      'blocks.footer_contact.zentrum_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Swiss Paraplegic Centre<br />
            Guido A. Zäch-Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:spz@paraplegie.ch">spz@paraplegie.ch</a><br />
            <a href="tel:+41419395454">T.&nbsp;+41 41 939 54 54</a><br />
          </div>
        </div>
      `,
      'blocks.footer_contact.parahelp_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">ParaHelp AG<br />
            Guido A. Zäch-Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:info@parahelp.ch">info@parahelp.ch</a><br />
            <a href="tel:+41419396060">T.&nbsp;+41 41 939 60 60</a>
          </div>
        </div>
      `,
      'blocks.content_footer.become_member': `
        <p>
          <strong>Become a member</strong> and receive <strong><span>250,000 francs</span></strong> should the unthinkable happen.
        </p>
      `,
      'blocks.content_footer.donate': `
        <p>
          <strong>Donate </strong>now and support our projects in favor of <strong>people affected by spinal cord injuries</strong>.
        </p>
      `,
      'footer.socialMenuTitle': `Swiss Paraplegic Foundation on Social Media`,
      'footer_organisation.socialMenuTitleSubtext': `on Social Media`,
      'newsroom.teaser.view-all': 'View all',
      'Show all': 'Show all',
      'newsroom.empty': 'There is no content that match the filters.',
      'newsroom.teaser.headline': 'Stories and News',
      'newsroom.teaser.subheadline': 'Our Articles in Focus',
      'cta.button_type.donate': 'Donate',
      'cta.button_type.membership': 'Become a member',
      'cta.button_type.gift': 'Give a gift now',
      'employer_branding.title':
        'Make the difference at the Swiss Paraplegic Group',
      'employer_branding.description':
        'Working in a globally unique service network and making a meaningful contribution every day. Our recognitions speak for themselves.',
      'employer_branding.cta_1.text': 'Work with us',
      'employer_branding.cta_1.url': 'https://karriere.paraplegie.ch/',
      'employer_branding.cta_2.text': 'Vacancies',
      'employer_branding.cta_2.url':
        'https://karriere.paraplegie.ch/de/was-uns-auszeichnet',
      'employer_branding.badges.one.img': '/img/badges/fws_en.png',
      'employer_branding.badges.one.alt': '',
      'employer_branding.badges.two.img': '/img/badges/safa_fr.png',
      'employer_branding.badges.two.alt':
        'Top Arbeitgeber 2024 - Kategorie 1000+ Mitarbeitenden',
      'job_listing_subscribe.message.success':
        'Thank you for your interest. You have successfully registered for the job subscription.',
      'job_listing_subscribe.message.error':
        'Failed to subscribe to the job newsletter',
      'job_listing_subscribe.validation.email.error':
        'Please enter your email address',
      'job_listing_subscribe.validation.subject.error':
        'Please enter a subject for the job newsletter',
      'job_listing_subscribe.validation.subject.validation':
        'You can only use alphanumeric characters in your subject',
      'job_listing_subscribe.title':
        'Create a job subscription with the selected criteria',
      'job_listing_subscribe.subtitle':
        'Get notified about new and matching open positions at the Swiss Paraplegic Group',
      'job_listing_subscribe.input.subject.label': 'Subject for job newsletter',
      'job_listing_subscribe.input.email.label': 'Your email address',
      'job_listing_subscribe.input.submit.label': 'Subscribe',
      'job_listing_filters.toggle.filters.show': '+ Additional filters',
      'job_listing_filters.toggle.filters.hide': '- Fewer filters',
      'job_listing_filters.input.search.label':
        'Search by profession or keyword',
      'job_listing_filters.input.organization.label': 'Organisation',
      'job_listing_filters.input.area.label': 'Area',
      'job_listing_filters.input.experience.label': 'Entry level',
      'job_listing_filters.input.workload.label': 'Workload',
      'job_listing_filters.input.location.label': 'Location',
      'job_listing_filters.toggle.subscribe': 'Subscribe to job newsletter',
      'job_listing_filters.input.reset.label': 'Reset',
      'job_listing_filters.input.submit.label': 'Apply',
      'job_listing_item.location': 'Location',
      'job_listing.message.error': 'Something has gone wrong',
      'job_listing.message.no_jobs': 'No jobs found',
      'job_listing.count_one': '{{count}} Job',
      'job_listing.count_other': '{{count}} Jobs',
      'job_listing.message.no_results': 'No results found',
      'multiselect.all': 'All',
    },
  },
  de: {
    urls: {
      search: '/de/search',
      newsList: '/de/news',
      newsListAll: '/de/news/all',
      eventList: '/de/events',
      eventListAll: '/de/events/all',
      blogList: '/de/blog',
      blogListAll: '/de/blog/all',
      historyList: '/de/history',
      doctorList: '/de/doctors',
    },
    translation: {
      '{{bytes}} Bytes': '{{bytes}} Bytes',
      '{{bytes}} GB': '{{bytes}} GB',
      '{{bytes}} KB': '{{bytes}} KB',
      '{{bytes}} MB': '{{bytes}} MB',
      '{{capacity}} cm<sup>3</sup>': '{{capacity}} cm<sup>3</sup>',
      '{{km}} km': '{{km}} km',
      '{{price}} CHF': '{{price}} CHF',
      '{{section}} Subnavigation': '{{section}} Subnavigation',
      '1st Circulation': '1. Inverkehrssetzung',
      '404 – Not found': '404 – Nicht gefunden',
      'Page not found': 'Nicht gefunden',
      'About us': 'Über uns',
      'ac_paraplegie_ch.name': 'Active Communication',
      'ac_paraplegie_ch.text':
        'Wir versorgen Menschen mit Behinderung oder einer neurologischen Erkrankung mit elektronischen und didaktischen Hilfsmitteln für mehr Teilhabe und Selbstbestimmung im Alltag.',
      'ac_paraplegie_ch.title': 'Active Communication',
      'Additional equipment': 'Sonderausstattung',
      All: 'Alle',
      'All companies from A-Z': 'Alle Firmen von A-Z',
      'All posts': 'Alle Beiträge',
      April: 'April',
      Arrival: 'Ankunft',
      August: 'August',
      Back: 'Zurück',
      'Back to {{domain}} Home': 'Zurück zur Startseite von {{domain}}',
      'Back to the frontpage.': 'Zurück zur Startseite',
      'Become a member': 'Mitglied werden',
      'Mitglied werden': 'Mitglied werden',
      Blog: 'Blog',
      'Book a room': 'Zimmer buchen',
      Brand: 'Marke',
      Capacity: 'Hubraum',
      Categories: 'Kategorien',
      Category: 'Kategorie',
      'Check availability': 'Zimmer buchen',
      CHF: 'CHF',
      'Choose donation amount': 'Spendenbetrag auswählen',
      'Click here for the homepage.': "Hier geht's zur Startseite.",
      'Close "{{section}}" Flyout': '"{{section}}" Flyout schliessen',
      'Close Photogallery (ESC)': 'Fotogalerie schliessen (ESC)',
      'Close search': 'Suche schliessen',
      Color: 'Farbe',
      'Companies by industries': 'Firmen nach Branchen',
      'Companies by industry': 'Firmen nach Branche',
      'Company name, place or industry': 'Firma, Ort oder Branche',
      Competences: 'Kompetenzen',
      Contact: 'Kontakt',
      'Contact {{domain}}': 'Kontaktieren Sie {{domain}}',
      'Current weather': 'Wetter derzeit',
      'Custom amount': 'Anderer Betrag',
      December: 'Dezember',
      Delete: 'Löschen',
      Departure: 'Abreise',
      Details: 'Details',
      'Doctors at the SPC': 'Ärztinnen und Ärzte im SPZ',
      Donate: 'Spenden',
      'Donate now': 'Jetzt spenden',
      'Donate now - short': 'Jetzt spenden',
      'Download document': 'Dokument herunterladen',
      'Each <em>Donation</em> counts': 'Jede <em>Spende</em> zählt',
      Edit: 'Bearbeiten',
      Email: 'E-Mail',
      Mail: 'Mail',
      'Main Treatment Areas': 'Behandlungsschwerpunkte',
      'Event info': 'Informationen zur Veranstaltung',
      Events: 'Veranstaltungen',
      Fax: 'Fax',
      February: 'Februar',
      Field: 'Fachgebiet',
      'Filter by organisation': 'Nach Organisation filtern',
      'Follow Link': 'Hyperlink folgen',
      Footer: 'Fusszeile',
      'forschung_paraplegie_ch.name': 'Schweizer Paraplegiker-Forschung',
      'forschung_paraplegie_ch.text':
        'Mit unserer Forschung setzen wir uns für die nachhaltige Verbesserung der Lebensqualität und Integration von Querschnittgelähmten ein.',
      'forschung_paraplegie_ch.title': 'Schweizer Paraplegiker-Forschung',
      'fst_paraplegie_ch.name': 'Innovationszentrum für assistive Technologien',
      'fst_paraplegie_ch.text':
        'Als Kompetenzzentrum und Wissensfabrik sind wir die Anlaufstelle für die Neu- und Weiterentwicklung von Hilfsmitteln für Querschnittgelähmte.',
      'fst_paraplegie_ch.title':
        'Innovationszentrum für assistive Technologien',
      Function: 'Funktion',
      Glossary: 'Alphabet',
      'gonner_vereinigung_paraplegie_ch.name': 'Gönner-Vereinigung',
      'gonner_vereinigung_paraplegie_ch.text':
        'Dank der Solidarität von über 1,8 Millionen Mitgliedern der Gönner-Vereinigung der Schweizer Paraplegiker-Stiftung kann das umfassende Leistungsnetz zugunsten Querschnittgelähmter aufrecht erhalten werden.',
      'gonner_vereinigung_paraplegie_ch.title': 'Gönner-Vereinigung',
      'Head of Departments': 'Chefärztinnen und Chefärzte',
      History: 'Geschichte',
      'History overview': 'Historische Übersicht',
      Home: 'Zur Startseite',
      Inside: 'Innen',
      'Is current page': 'Aktuelle Seite',
      January: 'Januar',
      July: 'Juli',
      June: 'Juni',
      KM: 'Km',
      Language: 'Sprache',
      'Latest news': 'Aktuelles',
      'Latest news overview': 'Übersicht der aktuellen Beiträge',
      'Learn more': 'Mehr erfahren',
      Links: 'Links',
      'Load more': 'Mehr laden',
      'Mail {{mail}}': 'Mail {{mail}}',
      'Mail: {{mail}}': 'E-Mail: {{mail}}',
      March: 'März',
      May: 'Mai',
      'Medical domain': 'Medizinischer Bereich',
      Memberships: 'Mitgliedschaft in Fachgesellschaften',
      Menu: 'Menü',
      MFK: 'MFK',
      Mobile: 'Mobil',
      Modification: 'Umbau',
      Month: 'Monat',
      More: 'Mehr',
      'More...': 'Mehr…',
      'Navigation area': 'Navigationsbereich',
      News: 'Aktuelles',
      Newsletter: 'Newsletter',
      'Newsletter Sign up': 'Newsletter-Anmeldung',
      Next: 'Weiter',
      'Next page': 'Nächste Seite',
      'Next years': 'Folgende Jahre',
      'No matter the amount, you make an important contribution to paraplegics.':
        'Egal in welcher Höhe, Sie leisten einen wichtigen Beitrag für Menschen mit Querschnittlähmung.',
      November: 'November',
      October: 'Oktober',
      'Oops, this page does not exist.<br />Would you like to help paraplegics?<br />Click on a heart.':
        'Hoppla, diese Seite existiert nicht.<br />Möchten Sie Querschnittgelähmte unterstützen?<br />Klicken Sie auf ein Herz.',
      'Open image in fullscreen': 'Bild im Vollbild öffnen',
      'Open navigation': 'Navigation öffnen',
      'Open Search': 'Suche öffnen',
      Options: 'Zubehör',
      Organisation: 'Organisation',
      Organisations: 'Organisationen',
      'orthotec_paraplegie_ch.name': 'Orthotec',
      'orthotec_paraplegie_ch.text':
        'Wir bieten massgeschneiderte Lösungen um die grösstmögliche Mobilität von Querschnittgelähmten zu gewährleisten.',
      'orthotec_paraplegie_ch.title': 'Orthotec',
      'orthotecsports_paraplegie_ch.name': 'Orthotecsports',
      'orthotecsports_paraplegie_ch.title': 'Orthotecsports',
      Page: 'Seite',
      'parahelp_paraplegie_ch.name': 'Parahelp',
      'parahelp_paraplegie_ch.text':
        'Wir beraten und begleiten Querschnittgelähmte und Ihre Angehörigen zu Hause.',
      'parahelp_paraplegie_ch.title': 'ParaHelp',
      'Paraplegie.ch blog overview': 'Paraplegie.ch blog Übersicht',
      'Paraplegie.ch doctors overview': 'Paraplegie.ch Ärzteübersicht',
      'Paraplegie.ch events overview': 'Paraplegie.ch Veranstaltungsübersicht',
      'paraplegie_ch.name': 'Paraplegie Hauptbereich',
      'paraplegie_ch.title': 'Schweizer Paraplegiker-Gruppe',
      Phone: 'Telefon',
      'Phone number: {{phone}}': 'Telefonnummer: {{phone}}',
      'Please input some keywords': 'Bitte geben Sie einen Suchbegriff ein.',
      'Please use other keywords for searching.':
        'Bitte versuchen Sie es mit einem anderen Suchbegriff.',
      Previous: 'Zurück',
      'Previous page': 'Vorherige Seite',
      'Previous years': 'Jahre zuvor',
      Price: 'Preis',
      'Price new': 'Neupreis',
      Print: 'Drucken',
      'Professional Career': 'Beruflicher Werdegang',
      PS: 'PS',
      Publications: 'Publikationen',
      Published: 'Veröffentlicht',
      Read: 'Lesen',
      'Read more': 'Mehr dazu',
      'Related posts': 'Weitere Beiträge',
      'Request an offer': 'Offertanfrage',
      'Scroll down': 'Nach unten scrollen',
      Search: 'Suchen',
      'Search company by name, industry or place':
        'Firmen nach Firma, Branche oder Ort suchen',
      'Search for <strong>{{term}}</strong>':
        'Suche nach <strong>{{term}}</strong>',
      'Search on Paraplegie.ch': 'Auf paraplegie.ch suchen',
      'Search term': 'Suchbegriff',
      Selected: 'Ausgewählt',
      'Senior Consultants': 'Leitende Ärztinnen und Ärzte',
      September: 'September',
      Services: 'Dienstleistungen',
      Share: 'Teilen',
      '--Show all--': '--Alle anzeigen--',
      'Show CV': 'Lebenslauf anzeigen',
      'Show next image': 'Nächstes Bild anzeigen',
      'Show previous image': 'Letztes Bild anzeigen',
      'sirmed_paraplegie_ch.name': 'Sirmed',
      'sirmed_paraplegie_ch.text':
        'Rehabilitation beginnt am Unfallort. Das Schweizer Institut für Rettungsmedizin Sirmed schult Ersthelfer zur bestmöglichen Versorgung von Nottfallpatienten.',
      'sirmed_paraplegie_ch.title': 'Sirmed',
      'Slide {{slide}}': 'Folie {{slide}}',
      'Specialty Registrars': 'Oberärztinnen und Oberärzte',
      Specialist: 'Fachärztinnen und Fachärzte',
      'Specialist title': 'Facharzttitel',
      'Specialist titles and specialisations': 'Facharzttitel und Schwerpunkte',
      'sportmedizin_paraplegie_ch.name': 'Sportmedizin Nottwil',
      'sportmedizin_paraplegie_ch.text':
        'Mit unserem umfassenden Angebot sportmedizinischer Dienstleistungen begleiten wir Sie gerne auf dem Weg zu Ihrem nächsten sportlichen Ziel.',
      'sportmedizin_paraplegie_ch.title': 'Sportmedizin Nottwil',
      'stiftung_paraplegie_ch.name': 'Schweizer Paraplegiker-Stiftung',
      'stiftung_paraplegie_ch.text':
        'Wir begleiten Querschnittgelähmte. Ein Leben lang.',
      'stiftung_paraplegie_ch.title': 'Schweizer Paraplegiker-Stiftung',
      Suche: 'Suche',
      'Support one of our heart projects for paraplegics':
        'eines unserer Herzensprojekte für Querschnittgelähmte.',
      'Paraplegiker-Gruppe': 'Paraplegiker-Gruppe',
      'Swiss Paraplegic Group': 'Schweizer Paraplegiker Gruppe',
      'Swiss Paraplegic Group on Facebook':
        'Paraplegiker-Stiftung auf Social Media',
      'Telephone {{phone}}': 'Telephone {{phone}}',
      'There are no blog posts that match your filters.':
        'Es wurde keine den Filteroptionen entsprechenden Beiträge gefunden.',
      'There are no companies that match your filters.':
        'Es wurden keine den Filteroptionen entsprechenden Firmen gefunden.',
      'There are no doctors that match your filters.':
        'Es wurden keine den Filtern entsprechenden Ärzte gefunden.',
      'There are no events that match your filters.':
        'Es wurden keine den Filteroptionen entsprechenden Veranstaltungen gefunden.',
      'There are no medical offers that match your filters.':
        'Es gibt keine medizinischen Angebote, die Ihren Filtern entsprechen.',
      'There are no news that match your filters.':
        'Es wurden keine den Filteroptionen entsprechenden Beiträge gefunden.',
      'To top': 'Nach oben',
      Toggle: 'Umschalten',
      'Training and Education': 'Aus- und Weiterbildungen',
      Translate: 'Übersetzen',
      'Unfortunately we could not find any content for this address. Perhaps you followed an old or incorrect link. Please check the address again.':
        'Leider konnten wir unter dieser Adresse keinen Inhalt finden. Vielleicht sind Sie einem falschen oder veralteten Link gefolgt - bitte überprüfen Sie diesen noch einmal.',
      'vereinigung_paraplegie_ch.name': 'Schweizer Paraplegiker-Vereinigung',
      'vereinigung_paraplegie_ch.text':
        'Als die nationale Selbsthilfe- und Fachorganisation der Querschnittgelähmten setzen wir uns gesamtschweizerisch für die Anliegen der Para- und Tetraplegiker ein.',
      'vereinigung_paraplegie_ch.title': 'Schweizer Paraplegiker-Vereinigung',
      Weather: 'Wetter',
      'Weather forecast for the next three days':
        'Wettervorhersage für die nächsten drei Tage',
      'Website {{website}}': 'Website {{website}}',
      Year: 'Jahr',
      Tomorrow: 'Morgen',
      Morgen: 'Morgen',
      Demain: 'Morgen',
      Domani: 'Morgen',
      Monday: 'Montag',
      Montag: 'Montag',
      Lundi: 'Montag',
      Lunedi: 'Montag',
      Tuesday: 'Dienstag',
      Wednesday: 'Mittwoch',
      Mittwoch: 'Mittwoch',
      Mercredi: 'Mittwoch',
      Mercoledi: 'Mittwoch',
      Thursday: 'Donnerstag',
      Donnerstag: 'Donnerstag',
      Jeudi: 'Donnerstag',
      Giovedi: 'Donnerstag',
      Friday: 'Freitag',
      Freitag: 'Freitag',
      Vendredi: 'Freitag',
      Venerdi: 'Freitag',
      Saturday: 'Samstag',
      Samstag: 'Samstag',
      Samedi: 'Samstag',
      Sabato: 'Samstag',
      Sunday: 'Sonntag',
      Sonntag: 'Sonntag',
      Dimanche: 'Sonntag',
      Domenica: 'Sonntag',
      'You are here': 'Sie befinden sich hier',
      'Your search returned <strong>{{results}} results</strong>.':
        'Ihre Suche ergab <strong>{{results}} Treffer</strong>.',
      'zentrum_paraplegie_ch.name': 'Schweizer Paraplegiker-Zentrum',
      'zentrum_paraplegie_ch.text':
        'Als Spezialklinik für Querschnittgelähmte setzen wir uns für die ganzheitliche Rehabilitation und umfassende Versorgung von Querschnittgelähmten ein.',
      'zentrum_paraplegie_ch.title': 'Schweizer Paraplegiker-Zentrum',
      Show: 'Anzeigen',
      'Future events': 'Kommende Veranstaltungen',
      'Past events': 'Vergangene Veranstaltungen',
      'Overview of all news': 'Übersicht alle News',
      'Overview of all events': 'Übersicht alle Events',
      'View on Google Maps': 'Auf Google Maps ansehen',
      Login: 'Login',
      Username: 'Username',
      Password: 'Password',
      Submit: 'Submit',
      'Sign up for the Paraplegic Foundation newsletter':
        'Für Newsletter der Paraplegiker Stiftung anmelden',
      'Email address': 'E-Mail-Adresse',
      Subscribe: 'Anmelden',
      Gender: 'Geschlecht',
      Male: 'Mann',
      Female: 'Frau',
      'Last name': 'Nachname',
      'First name': 'Vorname',
      Interest: 'Interesse',
      'Subscribe to our newsletter': 'Anmeldung Newsletter',
      'Total cost': 'Kosten total',
      'Personal data': 'Persönliche Angaben',
      Surname: 'Nachname',
      Company: 'Firma',
      Street: 'Strasse',
      'Street number': 'Nummer',
      'P.O. Box': 'Postfach',
      Postcode: 'PLZ',
      'Town/City': 'Ort',
      Country: 'Land',
      'Proceed to payment': 'Weiter zur Zahlung',
      'Your address': 'Ihre Adresse',
      'Ms.': 'Frau',
      'Mr.': 'Herr',
      'I would like the Centre for Pain Medicine to keep me informed about conferences and courses.':
        'Ich möchte vom Zentrum für Schmerzmedizin über Konferenzen und Kurse informiert werden.',
      'I would like to receive a payment confirmation for my employer.':
        'Ich möchte eine Zahlungsbestätigung für meinen Arbeitgeber erhalten.',
      Switzerland: 'Schweiz',
      Germany: 'Deutschland',
      France: 'Frankreich',
      Italy: 'Italien',
      Afghanistan: 'Afghanistan',
      Albania: 'Albanien',
      Algeria: 'Algerien',
      'American Samoa': 'Amerikanisch-Samoa',
      Andorra: 'Andorra',
      Angola: 'Angola',
      Anguilla: 'Anguilla',
      Antarctica: 'Antarktis',
      'Antigua and Barbuda': 'Antigua und Barbuda',
      Argentina: 'Argentinien',
      Armenia: 'Armenien',
      Aruba: 'Aruba',
      Australia: 'Australien',
      Austria: 'Österreich',
      Azerbaijan: 'Aserbeidschan',
      Bahamas: 'Bahamas',
      Bahrain: 'Bahrain',
      Bangladesh: 'Bangladesh',
      Barbados: 'Barbados',
      Belarus: 'Weissrussland',
      Belgium: 'Belgien',
      Belize: 'Belize',
      Benin: 'Benin',
      Bermuda: 'Bermuda-Inseln',
      Bhutan: 'Bhutan',
      Bolivia: 'Bolivien',
      'Bosnia and Herzegovina': 'Bosnien-Herzegowina',
      Botswana: 'Botswana',
      'Bouvet Island': 'Bouvet Inseln',
      Brazil: 'Brasilien',
      'British Indian Ocean Territory':
        'Britische Territorien im Indischen Ozean',
      'Brunei Darussalam': 'Brunei Darussalam',
      Bulgaria: 'Bulgarien',
      'Burkina Faso': 'Burkina Faso',
      Burundi: 'Burundi',
      Cambodia: 'Kambodscha',
      Cameroon: 'Kamerun',
      Canada: 'Kanada',
      'Cape Verde': 'Kapverden',
      'Cayman Islands': 'Kaiman-Inseln',
      'Central African Republic': 'Zentralafrikanische Republik',
      Chad: 'Tschad',
      Chile: 'Chile',
      China: 'China',
      'Christmas Island': 'Weihnachtsinsel',
      'Cocos (Keeling) Islands': 'Kokos-Inseln',
      Colombia: 'Columbien',
      Comoros: 'Komoren',
      'Congo (Brazzaville)': 'Kongo',
      'Congo (Kinshasa)': 'Kongo (Demokratische Republik)',
      'Cook Islands': 'Cook-Inseln',
      'Costa Rica': 'Costa Rica',
      Croatia: 'Kroatien',
      Cuba: 'Kuba',
      Cyprus: 'Zypern',
      'Czech Republic': 'Tschechische Republik',
      'Côte dIvoire': 'Elfenbeinküste',
      Denmark: 'Dänemark',
      Djibouti: 'Djibouti',
      Dominica: 'Dominica',
      'Dominican Republic': 'Dominikanische Republik',
      Ecuador: 'Equador',
      Egypt: 'Ägypten',
      'El Salvador': 'El Salvador',
      'Equatorial Guinea': 'Äquatorialguinea',
      Eritrea: 'Eritrea',
      Estonia: 'Estland',
      Ethiopia: 'Äthiopien',
      'Falkland Islands': 'Falkland-Inseln',
      'Faroe Islands': 'Färöer-Inseln',
      Fiji: 'Fidschi',
      Finland: 'Finnland',
      'French Guiana': 'Französisch-Guyana',
      'French Polynesia': 'Französisch-Polynesien',
      'French Southern Lands': 'Franz. Südpolarterritorien',
      Gabon: 'Gabun',
      Gambia: 'Gambia',
      Georgia: 'Georgien',
      Ghana: 'Ghana',
      Gibraltar: 'Gibraltar',
      Greece: 'Griechenland',
      Greenland: 'Grönland',
      Grenada: 'Grenada',
      Guadeloupe: 'Guadeloupe',
      Guam: 'Guam',
      Guatemala: 'Guatemala',
      Guernsey: 'Guernsey',
      Guinea: 'Guinea',
      'Guinea-Bissau': 'Guinea-Bissau',
      Guyana: 'Guyana',
      Haiti: 'Haiti',
      'Heard and McDonald Islands': 'Heard- MacDonal-Iseln',
      Honduras: 'Honduras',
      'Hong Kong': 'Hong Kong',
      Hungary: 'Ungarn',
      Iceland: 'Island',
      India: 'Indien',
      Indonesia: 'Indonesien',
      Iran: 'Iran',
      Iraq: 'Irak',
      Ireland: 'Irland',
      'Isle of Man': 'Insel Man',
      Israel: 'Israel',
      Jamaica: 'Jamaika',
      Japan: 'Japan',
      Jersey: 'Jersey',
      Jordan: 'Jordan',
      Kazakhstan: 'Kasachstan',
      Kenya: 'Kenia',
      Kiribati: 'Kiribati',
      'Korea, North': 'Nordkorea',
      'Korea, South': 'Südkorea',
      Kosovo: 'Kosovo',
      Kuwait: 'Kuwait',
      Kyrgyzstan: 'Kirgistan',
      Laos: 'Laos',
      Latvia: 'Lettland',
      Lebanon: 'Libanon',
      Lesotho: 'Lesotho',
      Liberia: 'Liberia',
      Libya: 'Lybien',
      Liechtenstein: 'Liechtenstein',
      Lithuania: 'Litauen',
      Luxembourg: 'Luxemburg',
      Macau: 'Macão',
      Macedonia: 'Mazedonien',
      Madagascar: 'Madagaskar',
      Malawi: 'Malawi',
      Malaysia: 'Malaysia',
      Maldives: 'Malediven',
      Mali: 'Mali',
      Malta: 'Malta',
      'Marshall Islands': 'Marshall-Inseln',
      Martinique: 'Martinique',
      Mauritania: 'Mauretanien',
      Mauritius: 'Mauritius',
      Mayotte: 'Mayotte',
      Mexico: 'Mexiko',
      Micronesia: 'Mikronesien',
      Moldova: 'Moldawien',
      Monaco: 'Monaco',
      Mongolia: 'Mongolei',
      Montenegro: 'Montenegro',
      Montserrat: 'Montserrat',
      Morocco: 'Marokko',
      Mozambique: 'Mosambik',
      Myanmar: 'Myanmar',
      Namibia: 'Namibien',
      Nauru: 'Nauru',
      Nepal: 'Nepal',
      Netherlands: 'Niederlande',
      'Netherlands Antilles': 'Niederländische Antillen',
      'New Caledonia': 'Neukaledonien',
      'New Zealand': 'Neuseeland',
      Nicaragua: 'Nicaragua',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Niue: 'Niue',
      'Norfolk Island': 'Norfolk-Insel',
      'Northern Mariana Islands': 'Nordliche Mariannen Insel',
      Norway: 'Norwegen',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Palau: 'Palau',
      Palestine: 'Palestina',
      Panama: 'Panama',
      'Papua New Guinea': 'Papua-Neuguinea',
      Paraguay: 'Paraguay',
      Peru: 'Peru',
      Philippines: 'Philippinen',
      Pitcairn: 'Pitcairn-Inseln',
      Poland: 'Polen',
      Portugal: 'Portugal',
      'Puerto Rico': 'Puerto Rico',
      Qatar: 'Katar',
      Reunion: 'Réunion',
      Romania: 'Rumänien',
      'Russian Federation': 'Russland (Russiche Föderation)',
      Rwanda: 'Ruanda',
      'Saint Helena': 'St. Helena',
      'Saint Kitts and Nevis': 'Saint Kitts und Nevis',
      'Saint Lucia': 'St. Lucia',
      'Saint Pierre and Miquelon': 'St. Pierre und Miquelon',
      'Saint Vincent and the Grenadines': 'St. Vincent und die Grenadinen',
      'Saint-Barthélemy': 'Sankt Bartholomäus',
      'Saint-Martin (French part)': 'Saint Martin (Französischer Teil)',
      Samoa: 'Samoa',
      'San Marino': 'SanMarino',
      'Sao Tome and Principe': 'SãoTomé und Principe',
      'Saudi Arabia': 'Saudi Arabien',
      Senegal: 'Senegal',
      Serbia: 'Serbien',
      Seychelles: 'Seyschellen',
      'Sierra Leone': 'Sierra Leone',
      Singapore: 'Singapur',
      Slovakia: 'Slowakei',
      Slovenia: 'Slowenien',
      'Solomon Islands': 'Salomon-Inseln',
      Somalia: 'Somalia',
      'South Africa': 'Südafrika',
      'South Georgia and South Sandwich Islands':
        'Süd Georgia und die südlichen Sandwich Inseln',
      Spain: 'Spanien',
      'Sri Lanka': 'Sri Lanka',
      Sudan: 'Sudan',
      Suriname: 'Surinam',
      'Svalbard and Jan Mayen Islands': 'Svalbard und Jan Mayen',
      Swaziland: 'Swasiland',
      Sweden: 'Schweden',
      Syria: 'Syrien',
      Taiwan: 'Taiwan',
      Tajikistan: 'Tadschikistan',
      Tanzania: 'Tansania',
      Thailand: 'Thailand',
      'Timor-Leste': 'Timor-Leste',
      Togo: 'Togo',
      Tokelau: 'Tokelau',
      Tonga: 'Tonga',
      'Trinidad and Tobago': 'Trinidad und Tobago',
      Tunisia: 'Tunesien',
      Turkey: 'Türkei',
      Turkmenistan: 'Turkmenistan',
      'Turks and Caicos Islands': 'Turks- Caicos-Isen',
      Tuvalu: 'Tuvalu',
      Uganda: 'Uganda',
      Ukraine: 'Ukraine',
      'United Arab Emirates': 'Vereinigete Arabische Emirate',
      'United Kingdom': 'Grossbritannien',
      'United States Minor Outlying Islands':
        'Übrige Inseln im Pazifik der USA',
      'United States of America': 'Vereinigte Staaten von Amerika',
      Uruguay: 'Uruguay',
      Uzbekistan: 'Usbekistan',
      Vanuatu: 'Vanuatu',
      'Vatican City': 'Vatikanstadt',
      Venezuela: 'Venezuela',
      Vietnam: 'Vietnam',
      'Virgin Islands, British': 'Jungfern-Inseln (UK)',
      'Virgin Islands, U.S.': 'Jungfern-Inseln (USA)',
      'Wallis and Futuna Islands': 'Wallis und Futuna',
      'Western Sahara': 'Westsahara',
      Yemen: 'Yemen',
      Zambia: 'Sambia',
      Zimbabwe: 'Simbabwe',
      Åland: 'Åland',
      'Edit your booking': 'Buchung bearbeiten',
      'At least one price must be selected.':
        'Es muss mindestens ein Preis ausgewählt werden.',
      'Salutation can not be blank.': 'Die Anrede darf nicht leer sein.',
      'First name can not be blank.': 'Der Vorname darf nicht leer sein.',
      'Last name can not be blank.': 'Der Nachname darf nicht leer sein.',
      'Email can not be blank.': 'Die E-Mail darf nicht leer sein.',
      'Email address is not valid.': 'E-Mail-Adresse ist ungültig.',
      'Clinic and department can not be blank.':
        'Die Klinik und Abteilung darf nicht leer sein.',
      'Street can not be blank.': 'Die Straße darf nicht leer sein.',
      'Postcode can not be blank.': 'Die Postleitzahl darf nicht leer sein.',
      'Postcode must be numeric.': 'Die Postleitzahl muss numerisch sein.',
      'Postcode must greater than 4 digits.':
        'Die Postleitzahl muss größer als 4 Ziffern sein.',
      'Town/City can not be blank.': 'Stadt darf nicht leer sein.',
      'Country can not be blank.': 'Land darf nicht leer sein.',
      'Latest blogs': 'Neuste Beiträge',
      'Clinic and department': 'Klinik und Abteilung',
      'Your course selection': 'Ihre Kursauswahl',
      Title: 'Titel',
      'I accept the General Terms and Conditions':
        'Ich bestätige, dass ich die AGBs gelesen habe und mit diesen einverstanden bin',
      'You will find our General Terms and Conditions here':
        'Unsere allgemeinen Geschäftsbedingungen (AGB) finden Sie hier',
      'You must accept the General Terms and Conditions':
        'Sie müssen die AGB akzeptieren',
      Membership: 'Mitgliedschaft',
      'Individual member': 'Einzelmitglied',
      'Married couples and families': 'Ehepaare und Familien',
      'Permanent member': 'Dauermitglied',
      'Become a member now': 'Jetzt Mitglied werden',
      'per year': 'pro Jahr',
      'One-time': 'Einmalig',
      'Contact us': 'Kontaktieren Sie uns',
      'Quick Links': 'Quick Links',
      'Main navigation': 'Hauptnavigation',
      'Member Service': 'Mitglieder-Service',
      'A part of': 'Ein Teil der',
      'We offer': 'Wir bieten',
      'Job Type': 'Firmen nach Angebot',
      'Donations for people with spinal cord injuries': `Spenden für Menschen mit Querschnitt&shy;lähmung`,
      'Your transaction is secure': 'Ihre Transaktion ist sicher',
      'Each Donation counts': 'Jede Spende zählt',
      '<em>Become</em> a member': '<em>Mitglied</em> werden',
      'And receive 250,000 francs in an emergency.':
        'Als Mitglied erhalten Sie 250 000 Franken bei unfallbedingter Querschnittlähmung.',
      'Couples and families': 'Paare und Familien',
      once: 'einmalig',
      'CHF 250,000 if the unthinkable happens': '250 000 Franken im Ernstfall',
      'Protect yourself - become a member now':
        'Jetzt Mitglied werden und vorsorgen',
      '1.9 million members have already put their trust in the Swiss Paraplegic Foundation.':
        'Bereits 2 Millionen Mitglieder vertrauen auf die Schweizer Paraplegiker-Stiftung.',
      '<em>Give the gift</em> of membership':
        'Mitgliedschaft <em>schenken</em>',
      'Your loved ones will receive 250,000 francs should the unthinkable happen.':
        'Im Ernstfall erhalten Ihre Liebsten 250 000 Franken.',
      'Gift now': 'Jetzt schenken',
      'A useful gift for your loved ones':
        'Ein sinnvolles Geschenk für Ihre Liebsten',
      'Giving a membership will provide joy and show you care.':
        'Mit einer Mitgliedschaft bereiten Sie Freude und sorgen vor.',
      'blocks.footer_contact': `
        <div class="FooterAddress">
          <div class="vcard">Schweizer Paraplegiker-Zentrum<br />
              Guido A. Zäch Strasse 1<br />
              6207 Nottwil<br />
              <a href="mailto:spz@paraplegie.ch">spz@paraplegie.ch</a><br />
              <a href="tel:+41419395454">T.&nbsp;+41 41 939 54 54</a>
          </div>
          <br />
          <div class="vcard">Schweizer Paraplegiker-Stiftung<br />
            Gönner-Vereinigung<br />
            Guido A. Zäch Strasse 6<br />
            6207 Nottwil<br />
            <a href="mailto:sps@paraplegie.ch">sps@paraplegie.ch</a><br />
            <a href="tel:+41419396262">T.&nbsp;+41 41 939 62 62</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.sportmedizin_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Sportmedizin Nottwil<br />
            Guido A. Zäch Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:sportmedizin@sportmedizin-nottwil.ch">sportmedizin@sportmedizin-nottwil.ch</a><br />
            <a href="tel:+41419396600">T.&nbsp;+41 41 939 66 00</a>
          </div>
          <br />
          <div class="vcard">
            <b>Telefonische Anmeldung</b><br />
            Montag bis Donnerstag<br />
            08.00 - 12.30 und 13.30 - 17.00 Uhr<br />
            Freitag<br />
            08.00 - 12.30 und 13.30 - 16.00 Uhr
          </div>
        </div>
      `,
      'blocks.footer_contact.sirmed_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">SIRMED<br />
            Schweizer Institut für Rettungsmedizin AG<br />
            Guido A. Zäch Strasse 2b<br />
            6207 Nottwil<br />
            <a href="mailto:info@sirmed.ch">info@sirmed.ch</a><br />
            <a href="tel:+41419395050">T.&nbsp;+41 41 939 50 50</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.activecommunication_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Active Communication<br />
            Sumpfstrasse 28<br />
            6312 Steinhausen<br />
            <a href="mailto:office@activecommunication.ch">office@activecommunication.ch</a><br />
            <a href="tel:+41417470303">T.&nbsp;+41 41 747 03 03</a><br />
            <a href="tel:+41417470300">T.&nbsp;+41 41 747 03 00 (Service-Center D)</a><br />
            <a href="tel:+41417470312">T.&nbsp;+41 41 747 03 12 (Service-Center F)</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.gonner_vereinigung_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Gönner-Vereinigung der Schweizer Paraplegiker-Stiftung<br />
            Guido A. Zäch Strasse 10<br />
            6207 Nottwil<br />
            <a href="mailto:sps@paraplegie.ch">sps@paraplegie.ch</a><br />
            <a href="tel:+41419396262">T.&nbsp;+41 41 939 62 62</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.forschung_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Schweizer Paraplegiker-Forschung<br />
            Guido A. Zäch-Strasse 4<br />
            6207 Nottwil<br />
            <a href="mailto:spf@paraplegie.ch">spf@paraplegie.ch</a><br />
            <a href="tel:+41419396565">T.&nbsp;+41 41 939 65 65</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.orthotec_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Orthotec AG<br />
            Guido A. Zäch Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:info@orthotec.ch">info@orthotec.ch</a><br />
            <a href="tel:+41419395606">T.&nbsp;+41 41 939 56 06</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.orthotecsports_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Orthotec AG<br />
            Guido A. Zäch Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:info@orthotec.ch">info@orthotec.ch</a><br />
            <a href="tel:+41419395606">T.&nbsp;+41 41 939 56 06</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.stiftung_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Schweizer Paraplegiker-Stiftung<br />
            Guido A. Zäch Strasse 10<br />
            6207 Nottwil<br />
            <a href="mailto:sps.sec@paraplegie.ch">sps.sec@paraplegie.ch</a><br />
            <a href="tel:+41419396363">T.&nbsp;+41 41 939 63 63</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.zentrum_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Schweizer Paraplegiker-Zentrum<br />
            Guido A. Zäch-Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:spz@paraplegie.ch">spz@paraplegie.ch</a><br />
            <a href="tel:+41419395454">T.&nbsp;+41 41 939 54 54</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.parahelp_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">ParaHelp AG<br />
            Guido A. Zäch-Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:info@parahelp.ch">info@parahelp.ch</a><br />
            <a href="tel:+41419396060">T.&nbsp;+41 41 939 60 60</a>
          </div>
        </div>
      `,
      'blocks.content_footer.become_member': `
        <p>
          <strong>Werden Sie jetzt Mitglied</strong> und erhalten Sie im Ernstfall <strong><span>250 000 Franken</span></strong>.
        </p>
      `,
      'blocks.content_footer.donate': `
        <p>
          <strong>Spenden </strong>Sie jetzt und unterstützen Sie unsere Projekte zugunsten von <strong>Querschnittgelähmten</strong>.
        </p>
      `,
      'footer.socialMenuTitle': `Schweizer Paraplegiker-Stiftung auf Social Media`,
      'footer_organisation.socialMenuTitleSubtext': `auf Social Media`,
      'newsroom.teaser.view-all': 'Alle anzeigen',
      'Show all': 'Alle anzeigen',
      'newsroom.empty': 'Es gibt keine Inhalte, die den Filtern entsprechen.',
      'newsroom.teaser.headline': 'Stories und News',
      'newsroom.teaser.subheadline': 'Unsere Artikel im Fokus',
      'cta.button_type.donate': 'Spenden',
      'cta.button_type.membership': 'Mitglied werden',
      'cta.button_type.gift': 'Jetzt schenken',
      'employer_branding.title':
        'Den Unterschied machen bei der Schweizer Paraplegiker-Gruppe',
      'employer_branding.description':
        'Arbeiten in einem weltweit einzigartigen Leistungsnetz und täglich einen sinnvollen Beitrag leisten. Unsere Anerkennungen sprechen für sich.',
      'employer_branding.cta_1.text': 'Arbeiten bei uns',
      'employer_branding.cta_1.url':
        'https://www.paraplegie.ch/de/karriere-in-der-spg/',
      'employer_branding.cta_2.text': 'Offene Stellen',
      'employer_branding.cta_2.url':
        'https://www.paraplegie.ch/de/karriere/offene-stellen/',
      'employer_branding.badges.one.img': '/img/badges/fws_en.png',
      'employer_branding.badges.one.alt': '',
      'employer_branding.badges.two.img': '/img/badges/safa_de.png',
      'employer_branding.badges.two.alt':
        'Top Arbeitgeber 2024 - Kategorie 1000+ Mitarbeitenden',
      'job_listing_subscribe.message.success':
        'Vielen Dank für dein Interesse. Du hast dich erfolgreich für das Job-Abo registriert.',
      'job_listing_subscribe.message.error':
        'Die Anmeldung für das Job-Abo ist fehlgeschlagen',
      'job_listing_subscribe.validation.email.error':
        'Bitte E-Mail Adresse eingeben',
      'job_listing_subscribe.validation.subject.error':
        'Bitte einen Titel für das Job-Abo eingeben',
      'job_listing_subscribe.validation.subject.validation':
        'Es können nur alphanummerische Zeichen im Betreff verwendet werden.',
      'job_listing_subscribe.title':
        'Job-Abo mit den ausgewählten Kriterien erstellen',
      'job_listing_subscribe.subtitle':
        'Erfahre automatisch von neuen und passenden Jobangeboten der Schweizer Paraplegiker-Gruppe.',
      'job_listing_subscribe.input.subject.label': 'Betreff für Job-Newsletter',
      'job_listing_subscribe.input.email.label': 'Ihre E-Mail Adresse',
      'job_listing_subscribe.input.submit.label': 'Abonnieren',
      'job_listing_filters.toggle.filters.show': '+ Weitere Filter',
      'job_listing_filters.toggle.filters.hide': '- Weniger Filter',
      'job_listing_filters.input.search.label':
        'Suche nach Beruf oder Stichwort',
      'job_listing_filters.input.organization.label': 'Unternehmen',
      'job_listing_filters.input.area.label': 'Bereich',
      'job_listing_filters.input.experience.label': 'Einstieg als',
      'job_listing_filters.input.workload.label': 'Pensum',
      'job_listing_filters.input.location.label': 'Standort',
      'job_listing_filters.toggle.subscribe': 'Job-Abo abonnieren',
      'job_listing_filters.input.reset.label': 'Zurücksetzen',
      'job_listing_filters.input.submit.label': 'Anwenden',
      'job_listing_item.location': 'Standort',
      'job_listing.message.error': 'Etwas ist schief gegangen.',
      'job_listing.message.no_jobs': 'Keine Jobs gefunden',
      'job_listing.count_one': '{{count}} Job',
      'job_listing.count_other': '{{count}} Jobs',
      'job_listing.message.no_results': 'Keine Jobs gefunden',
      'multiselect.all': 'Alle',
    },
  },
  fr: {
    urls: {
      search: '/fr/search',
      newsList: '/fr/news',
      newsListAll: '/fr/news/all',
      eventList: '/fr/events',
      eventListAll: '/fr/events/all',
      blogList: '/fr/blog',
      blogListAll: '/fr/blog/all',
      historyList: '/fr/history',
      doctorList: '/fr/doctors',
    },
    translation: {
      '{{bytes}} Bytes': '{{bytes}} octets',
      '{{bytes}} GB': '{{bytes}} Mo',
      '{{bytes}} KB': '{{bytes}} Ko',
      '{{bytes}} MB': '{{bytes}} Mo',
      '{{capacity}} cm<sup>3</sup>': '{{capacity}} cm<sup>3</sup>',
      '{{km}} km': '{{km}} km',
      '{{price}} CHF': '{{price}} CHF',
      '{{section}} Subnavigation': 'Sous-navigation{{section}}',
      '1st Circulation': '1re mise en circulation',
      '404 – Not found': '404 – page impossible d’afficher',
      'Page not found': 'Page impossible d’afficher',
      'About us': 'Qui sommes-nous ?',
      'ac_paraplegie_ch.name': 'Active Communication',
      'ac_paraplegie_ch.text':
        'Nous fournissons aux personnes vivant avec un handicap ou une maladie neurologique des moyens auxiliaires électroniques et didactiques afin qu’elles puissent participer davantage à la vie sociale et avoir une vie autodéterminée au quotidien.',
      'ac_paraplegie_ch.title': 'Active Communication',
      'Additional equipment': 'Équipement spécial',
      All: 'Tous',
      'All companies from A-Z': 'Toutes les entreprises de A à Z',
      'All posts': 'Tous les articles',
      April: 'Avril',
      Arrival: 'Arrivée',
      August: 'Août',
      Back: 'Retour',
      'Back to {{domain}} Home': 'Revenir à la page d’accueil de {{domain}}',
      'Back to the frontpage.': 'Revenir à la page d’accueil',
      'Become a member': 'Devenir membre',
      'Mitglied werden': 'Devenir membre',
      Blog: 'Blog',
      'Book a room': 'Réserver une chambre',
      Brand: 'Marque',
      Capacity: 'Cylindrée',
      Categories: 'Catégories',
      Category: 'Catégorie',
      'Check availability': 'Vérifier les disponibilités',
      CHF: 'CHF',
      'Choose donation amount': 'Sélectionner le montant du don',
      'Click here for the homepage.':
        "Cliquez ici pour accéder à la page d'accueil. ",
      'Close "{{section}}" Flyout': 'Fermer le menu déroulant"{{section}}"',
      'Close Photogallery (ESC)': 'Fermer la galerie (ESC)',
      'Close search': 'Fermer la fenêtre de recherche',
      Color: 'Couleur',
      'Companies by industries': 'Entreprises triées par branches',
      'Companies by industry': 'Entreprises triées par branche',
      'Company name, place or industry': 'Entreprises, Localité ou branche',
      Competences: 'Compétences',
      Contact: 'Contact',
      'Contact {{domain}}': 'Contactez {{domain}}',
      'Current weather': 'La météo en ce moment',
      'Custom amount': 'Autre montant',
      December: 'Décembre',
      Delete: 'Supprimer',
      Departure: 'Départ',
      Details: 'Détails',
      'Doctors at the SPC': 'Les médecins du CSP',
      Donate: 'Dons',
      'Donate now': 'Faire un don maintenant',
      'Donate now - short': 'Faire un don',
      'Download document': 'Télécharger un document',
      'Each <em>Donation</em> counts': 'Chaque <em>don</em> compte',
      Edit: 'Modifier',
      Email: 'E-mail',
      Mail: 'Mail',
      'Main Treatment Areas': 'Priorités thérapeutiques',
      'Event info': 'Informations à propos de la manifestation',
      Events: 'Manifestations',
      Fax: 'Téléfax',
      February: 'Février',
      Field: 'Spécialisation',
      'Filter by organisation': 'Mettre un filtre par organisation',
      'Follow Link': 'Afficher le lien hypertexte',
      Footer: 'Pied de page',
      'forschung_paraplegie_ch.name': 'Recherche suisse pour paraplégiques',
      'forschung_paraplegie_ch.text':
        'Avec nos recherches, nous nous employons en vue d’une amélioration durable de la qualité de vie et de la réinsertion des paralysés médullaires.',
      'forschung_paraplegie_ch.title': 'Recherche suisse pour paraplégiques',
      'fst_paraplegie_ch.name':
        'Centre d’innovation pour les technologies d’assistance',
      'fst_paraplegie_ch.text':
        'Point de contact abritant un centre de compétence et un pôle des connaissances, notre objectif est développer des moyens auxiliaires pour personnes paralysées médullaires.',
      'fst_paraplegie_ch.title':
        'Centre d’innovation pour les technologies d’assistance',
      Function: 'Fonction',
      Glossary: 'Par ordre alphabétique',
      'gonner_vereinigung_paraplegie_ch.name': 'Association des bienfaiteurs',
      'gonner_vereinigung_paraplegie_ch.text':
        'La solidarité de plus de 18 millions de membres de l’Association des bienfaiteurs de la Fondation suisse pour paraplégiques permet de maintenir le réseau de prestations en faveur des paralysés médullaires.',
      'gonner_vereinigung_paraplegie_ch.title': 'Association des bienfaiteurs',
      'Head of Departments': 'Médecins-chefs',
      History: 'Histoire',
      'History overview': 'Aperçu historique',
      Home: 'Revenir à la page d’accueil',
      Inside: 'Habitacle',
      'Is current page': 'Page actuelle',
      January: 'Janvier',
      July: 'Juillet',
      June: 'Juin',
      KM: 'Kilométrage',
      Language: 'Langue',
      'Latest news': 'Actualités',
      'Latest news overview': 'Aperçu des articles actuels',
      'Learn more': 'En savoir plus',
      Links: 'Liens',
      'Load more': 'Afficher plus',
      'Mail {{mail}}': 'Mail {{mail}}',
      'Mail: {{mail}}': 'E-mail : {{mail}}',
      March: 'Mars',
      May: 'Mai',
      'Medical domain': 'Domaine médical',
      Memberships: 'Membre dans différentes sociétés spécialisées',
      Menu: 'Menu',
      MFK: 'Contrôle technique (SdA)',
      Mobile: 'Portable',
      Modification: 'Transformation',
      Month: 'Mois',
      More: 'Plus',
      'More...': 'Plus …',
      'Navigation area': 'Zone de navigation',
      News: 'Actualités',
      Newsletter: 'Newsletter',
      'Newsletter Sign up': "S'abonner à la newsletter",
      Next: 'Suite',
      'Next page': 'Page suivante',
      'Next years': 'Années suivantes',
      'No matter the amount, you make an important contribution to paraplegics.':
        'Quelle que soit la somme, vous apportez une contribution importante pour les personnes paralysées médullaires.',
      November: 'Novembre',
      October: 'Octobre',
      'Oops, this page does not exist.<br />Would you like to help paraplegics?<br />Click on a heart.':
        'Oups, cette page n’existe pas.<br />Souhaitez-vous soutenir les paralysés médullaires ?<br />Cliquez alors sur un cœur.',
      'Open image in fullscreen': 'Ouvrir l’image en plein écran',
      'Open navigation': 'Ouvrir la navigation',
      'Open Search': 'Lancer une recherche',
      Options: 'Accessoires',
      Organisation: 'Organisation',
      Organisations: 'Organisations',
      'orthotec_paraplegie_ch.name': 'Orthotec',
      'orthotec_paraplegie_ch.text':
        'Nos solutions sur mesure offrent aux personnes blessées médullaires la plus grande mobilité possible.',
      'orthotec_paraplegie_ch.title': 'Orthotec',
      'orthotecsports_paraplegie_ch.name': 'Orthotecsports',
      'orthotecsports_paraplegie_ch.title': 'Orthotecsports',
      Page: 'Page',
      'parahelp_paraplegie_ch.name': 'Parahelp',
      'parahelp_paraplegie_ch.text':
        'Nous conseillons et accompagnons les paralysés médullaires et leurs proches à la maison.',
      'parahelp_paraplegie_ch.title': 'ParaHelp',
      'Paraplegie.ch blog overview': 'Aperçu blog paraplegie.ch',
      'Paraplegie.ch doctors overview': 'Aperçu des médecins paraplegie.ch',
      'Paraplegie.ch events overview':
        'Aperçu des manifestations paraplegie.ch',
      'paraplegie_ch.name': 'Domaine principal paraplégie',
      'paraplegie_ch.title': 'Groupe suisse pour paraplégiques',
      Phone: 'Téléphone',
      'Phone number: {{phone}}': 'Numéro de téléphone : {{phone}}',
      'Please input some keywords':
        'Veuillez saisir un terme pour la recherche.',
      'Please use other keywords for searching.':
        'Veuillez essayer un autre terme de recherche.',
      Previous: 'Retour',
      'Previous page': 'Page précédente',
      'Previous years': 'Années précédentes',
      Price: 'Prix',
      'Price new': 'Prix neuf',
      Print: 'Imprimer',
      'Professional Career': 'Curriculum vitae',
      PS: 'ch',
      Publications: 'Publications',
      Published: 'Publié',
      Read: 'Lire',
      'Read more': 'En savoir plus',
      'Related posts': 'Autres articles',
      'Request an offer': 'Demander une offre',
      'Scroll down': 'Défiler vers le bas',
      Search: 'Rechercher',
      'Search company by name, industry or place':
        'Rechercher des entreprises en fonction de la branche ou de la localité',
      'Search for <strong>{{term}}</strong>':
        'Rechercher <strong>{{term}}</strong>',
      'Search on Paraplegie.ch': 'Rechercher sur paraplegie.ch',
      'Search term': 'Terme de recherche',
      Selected: 'Sélectionné',
      'Senior Consultants': 'Médecins adjoints',
      September: 'Septembre',
      Services: 'Services',
      Share: 'Partager',
      '--Show all--': 'Afficher tous les domaines',
      'Show CV': 'Afficher le CV',
      'Show next image': 'Afficher l’image suivante',
      'Show previous image': "Afficher l'image précédente",
      'sirmed_paraplegie_ch.name': 'Sirmed',
      'sirmed_paraplegie_ch.text':
        'La réadaptation commence sur les lieux de l’accident. L’Institut suisse de médecine d’urgence Sirmed forme les personnes apportant les premiers secours pour la meilleure prise en charge des urgences possible.',
      'sirmed_paraplegie_ch.title': 'Sirmed',
      'Slide {{slide}}': 'Diapo {{slide}}',
      'Specialty Registrars': 'Médecins chefs de clinique',
      Specialist: 'Médecin spécialiste',
      'Specialist title': 'Titre de spécialiste',
      'Specialist titles and specialisations':
        'Titres de spécialistes et spécialisations',
      'sportmedizin_paraplegie_ch.name': 'Médecine du sport Nottwil',
      'sportmedizin_paraplegie_ch.text':
        'Nous vous aidons à atteindre votre objectif sportif avec notre offre complète de prestations de service en médecine du sport.',
      'sportmedizin_paraplegie_ch.title': 'Médecine du sport de Nottwil',
      'stiftung_paraplegie_ch.name': 'Fondation suisse pour paraplégiques',
      'stiftung_paraplegie_ch.text':
        'Nous accompagnons les paralysés médullaires. Tout au long de leur vie.',
      'stiftung_paraplegie_ch.title': 'Fondation suisse pour paraplégiques',
      Suche: 'Rechercher',
      'Support one of our heart projects for paraplegics':
        'l’un des projets pour paralysés médullaires qui nous tient à cœur.',
      'Paraplegiker-Gruppe': 'Groupe pour paraplégiques',
      'Swiss Paraplegic Group': 'Groupe suisse pour paraplégiques',
      'Swiss Paraplegic Group on Facebook':
        'Fondation suisse pour paraplégiques sur les réseaux sociaux',
      'Telephone {{phone}}': 'Telephone {{phone}}',
      'There are no blog posts that match your filters.':
        'Aucun article n’a été trouvé pour ces options de filtrage.',
      'There are no companies that match your filters.':
        'Aucune entreprise ne correspond à vos critères.',
      'There are no doctors that match your filters.':
        'Aucun médecin n’a été trouvé selon vos critères.',
      'There are no events that match your filters.':
        'Aucune manifestation n’a été trouvée pour ces options de filtrage.',
      'There are no medical offers that match your filters.':
        'Aucune offre ne correspond à vos critères.',
      'There are no news that match your filters.':
        'Aucun article n’a été trouvé pour ces options de filtrage.',
      'To top': 'Revenir en haut',
      Toggle: 'Basculer',
      'Training and Education': 'Formation et formations continues',
      Translate: 'Traduire',
      'Unfortunately we could not find any content for this address. Perhaps you followed an old or incorrect link. Please check the address again.':
        'Cette page est malheureusement introuvable. Vous avez peut-être saisi une adresse vieillie ou incorrecte. Veuillez vérifier l’adresse.',
      'vereinigung_paraplegie_ch.name': 'Association suisse des paraplégiques',
      'vereinigung_paraplegie_ch.text':
        'En tant qu’organisation d’entraide des paralysés médullaires, nous nous engageons dans toute la Suisse pour les intérêts des paraplégiques et tétraplégiques.',
      'vereinigung_paraplegie_ch.title': 'Association suisse des paraplégiques',
      Weather: 'Météo',
      'Weather forecast for the next three days':
        'Météo Prévisions météo pour les 3 prochains jours',
      'Website {{website}}': 'Website {{website}}',
      Year: 'Année',
      Tomorrow: 'Demain',
      Morgen: 'Demain',
      Demain: 'Demain',
      Domani: 'Demain',
      Monday: 'Lundi',
      Montag: 'Lundi',
      Lundi: 'Lundi',
      Lunedi: 'Lundi',
      Tuesday: 'Mardi',
      Wednesday: 'Mercredi',
      Mittwoch: 'Mercredi',
      Mercredi: 'Mercredi',
      Mercoledi: 'Mercredi',
      Thursday: 'Jeudi',
      Donnerstag: 'Jeudi',
      Jeudi: 'Jeudi',
      Giovedi: 'Jeudi',
      Friday: 'Vendredi',
      Freitag: 'Vendredi',
      Vendredi: 'Vendredi',
      Venerdi: 'Vendredi',
      Saturday: 'Samedi',
      Samstag: 'Samedi',
      Samedi: 'Samedi',
      Sabato: 'Samedi',
      Sunday: 'Dimanche',
      Sonntag: 'Dimanche',
      Dimanche: 'Dimanche',
      Domenica: 'Dimanche',
      'You are here': 'Vous êtes ici',
      'Your search returned <strong>{{results}} results</strong>.':
        'Votre recherche a donné <strong>{{results}} résultat-s</strong>.',
      'zentrum_paraplegie_ch.name': 'Centre suisse des paraplégiques',
      'zentrum_paraplegie_ch.text':
        'En tant que clinique spécialisée pour les paralysés médullaires, nous nous engageons en vue de leurs rééducation intégrale et suivi complet.',
      'zentrum_paraplegie_ch.title': 'Centre suisse des paraplégiques',
      Show: 'Afficher',
      'Future events': 'Événements à venir',
      'Past events': 'Événements passés',
      'Overview of all news': 'Aperçu de toutes les actualités',
      'Overview of all events': 'Aperçu de toutes les manifestations',
      'View on Google Maps': 'Voir sur Google Maps',
      Login: 'Login',
      Username: 'Username',
      Password: 'Password',
      Submit: 'Submit',
      'Sign up for the Paraplegic Foundation newsletter':
        'Sign up for the Paraplegic Foundation newsletter',
      'Email address': 'Adresse e-mail',
      Subscribe: "S'abonner",
      Gender: 'Gender',
      Male: 'Male',
      Female: 'Female',
      'Last name': 'Last name',
      'First name': 'First name',
      Interest: 'Interest',
      'Subscribe to our newsletter': 'Subscribe to our newsletter',
      'Total cost': 'Total cost',
      'Personal data': 'Personal data',
      Surname: 'Surname',
      Company: 'Company',
      Street: 'Street',
      'Street number': 'Street number',
      'P.O. Box': 'P.O. Box',
      Postcode: 'Postcode',
      'Town/City': 'Town/City',
      Country: 'Country',
      'Proceed to payment': 'Proceed to payment',
      'Your address': 'Your address',
      'Ms.': 'Ms.',
      'Mr.': 'Mr.',
      'I would like the Centre for Pain Medicine to keep me informed about conferences and courses.':
        'I would like the Centre for Pain Medicine to keep me informed about conferences and courses.',
      'I would like to receive a payment confirmation for my employer.':
        'I would like to receive a payment confirmation for my employer.',
      'Latest blogs': 'Articles récents',
      Membership: 'Affiliation',
      'Individual member': 'Membre individuel',
      'Married couples and families': 'Conjoints et familles',
      'Permanent member': 'Membre permanent',
      'Become a member now': 'Devenir membre maintenant',
      'per year': 'par an',
      'One-time': 'Unique',
      'Contact us': 'Contactez-nous',
      'Quick Links': 'Liens rapides',
      'Main navigation': 'Navigation principale',
      'Member Service': 'Service bienfaiteurs',
      'A part of': 'Une partie de',
      'We offer': 'Nous offrons',
      'Job Type': 'Job Type',
      'Donations for people with spinal cord injuries':
        'Dons pour les paralysé-es médullaires',
      'Your transaction is secure': 'Votre transaction est sécurisée',
      'Each Donation counts': 'Chaque don compte',
      '<em>Become</em> a member': 'Devenir <em>membre</em>',
      'And receive 250,000 francs in an emergency.':
        'En tant que membre, vous touchez 250 000 francs en cas de paralysie médullaire suite à un accident, avec dépendance permanente du fauteuil roulant.',
      'Couples and families': 'Couples et familles',
      once: 'unique',
      'CHF 250,000 if the unthinkable happens':
        '250 000 francs en cas de coup dur',
      'Protect yourself - become a member now':
        'Adhérez et prenez vos précautions',
      '1.9 million members have already put their trust in the Swiss Paraplegic Foundation.':
        '2 millions de membres font déjà confiance à la Fondation suisse pour paraplégiques.',
      '<em>Give the gift</em> of membership': '<em>Offrir</em> une affiliation',
      'Your loved ones will receive 250,000 francs should the unthinkable happen.':
        'En cas de coup dur, vos êtres chers touchent 250 000 francs.',
      'Gift now': 'Offrir maintenant',
      'A useful gift for your loved ones':
        'Un cadeau utile pour les êtres qui vous sont chers',
      'Giving a membership will provide joy and show you care.':
        'En offrant une affiliation, vous offrez aussi du plaisir et une part de prévoyance.',
      'blocks.footer_contact': `
        <div class="FooterAddress">
          <div class="vcard">Centre suisse des paraplégiques<br />
              Guido A. Zäch Strasse 1<br />
              6207 Nottwil<br />
              <a href="mailto:spz@paraplegie.ch">spz@paraplegie.ch</a><br />
              <a href="tel:+41419395454">T.&nbsp;+41 41 939 54 54</a>
          </div>
          <br />
          <div class="vcard">Fondation suisse pour paraplégiques<br />
            Association des bienfaiteurs<br />
            Guido A. Zäch Strasse 6<br />
            6207 Nottwil<br />
            <a href="mailto:sps@paraplegie.ch">sps@paraplegie.ch</a><br />
            <a href="tel:+41419396262">T.&nbsp;+41 41 939 62 62</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.sportmedizin_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Médecine du sport Nottwil<br />
            Guido A. Zäch Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:sportmedizin@sportmedizin-nottwil.ch">sportmedizin@sportmedizin-nottwil.ch</a><br />
            <a href="tel:+41419396600">T.&nbsp;+41 41 939 66 00</a>
          </div>
          <br />
          <div class="vcard">
            <b>Inscription</b><br />
            Lundi au jeudi<br />
            8h - 12h30 et 13h30 - 17h<br />
            vendredi<br />
            8h - 12h30 et 13h30 - 16h
          </div>
        </div>
      `,
      'blocks.footer_contact.sirmed_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">SIRMED<br />
            Institut suisse de Médecine d'Urgence<br />
            Guido A. Zäch Strasse 2b<br />
            6207 Nottwil<br />
            <a href="mailto:info@sirmed.ch">info@sirmed.ch</a><br />
            <a href="tel:+41419395050">T.&nbsp;+41 41 939 50 50</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.activecommunication_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Active Communication</b><br />
            Sumpfstrasse 28<br />
            6312 Steinhausen<br />
            <a href="mailto:office@activecommunication.ch">office@activecommunication.ch</a><br />
            <a href="tel:+41417470313">T.&nbsp;+41 41 747 03 13</a><br />
            <a href="tel:+41417470312">T.&nbsp;+41 41 747 03 12 (Service clients)</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.gonner_vereinigung_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Association des bienfaiteurs de la fondation suisse pour paraplégiques<br />
            Guido A. Zäch Strasse 10<br />
            6207 Nottwil<br />
            <a href="mailto:sps@paraplegie.ch">sps@paraplegie.ch</a><br />
            <a href="tel:+41419396262">T.&nbsp;+41 41 939 62 62</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.forschung_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Recherche suisse pour paraplégiques<br />
            Guido A. Zäch-Strasse 4<br />
            6207 Nottwil<br />
            <a href="mailto:spf@paraplegie.ch">spf@paraplegie.ch</a><br />
            <a href="tel:+41419396565">T.&nbsp;+41 41 939 65 65</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.orthotec_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Orthotec AG<br />
            Guido A. Zäch Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:info@orthotec.ch">info@orthotec.ch</a><br />
            <a href="tel:+41419395606">T.&nbsp;+41 41 939 56 06</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.orthotecsports_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Orthotec AG<br />
            Guido A. Zäch Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:info@orthotec.ch">info@orthotec.ch</a><br />
            <a href="tel:+41419395606">T.&nbsp;+41 41 939 56 06</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.stiftung_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Fondation suisse pour paraplégiques<br />
            Guido A. Zäch Strasse 10<br />
            6207 Nottwil<br />
            <a href="mailto:sps.sec@paraplegie.ch">sps.sec@paraplegie.ch</a><br />
            <a href="tel:+41419396363">T.&nbsp;+41 41 939 63 63</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.zentrum_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Centre suisse des paraplégiques<br />
            Guido A. Zäch-Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:spz@paraplegie.ch">spz@paraplegie.ch</a><br />
            <a href="tel:+41419395454">T.&nbsp;+41 41 939 54 54</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.parahelp_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">ParaHelp AG<br />
            Guido A. Zäch-Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:info@parahelp.ch">info@parahelp.ch</a><br />
            <a href="tel:+41419396060">T.&nbsp;+41 41 939 60 60</a>
          </div>
        </div>
      `,
      'blocks.content_footer.become_member': `
        <p>
          <strong>Adhérez</strong> et touchez le montant de soutien de <strong><span>250 000 francs</span></strong> en cas de coup dur.
        </p>
      `,
      'blocks.content_footer.donate': `
        <p>
          <strong>Faites un don </strong>maintenant et soutenez nos projets <strong>en faveur des paralysés médullaires</strong>.
        </p>
      `,
      'footer.socialMenuTitle': `Fondation suisse pour paraplégiques sur les réseaux sociaux`,
      'footer_organisation.socialMenuTitleSubtext': `sur les réseaux sociaux`,
      'newsroom.teaser.view-all': 'Voir tout',
      'Show all': 'Voir tout',
      'newsroom.empty': 'Aucun contenu ne correspond aux filtres.',
      'newsroom.teaser.headline': 'Stories and News',
      'newsroom.teaser.subheadline': 'Our Articles in Focus',
      'cta.button_type.donate': 'Dons',
      'cta.button_type.membership': 'Devenir membre',
      'cta.button_type.gift': 'Offrir maintenant',
      'employer_branding.title':
        'Make the difference at the Swiss Paraplegic Group',
      'employer_branding.description':
        'Working in a globally unique service network and making a meaningful contribution every day. Our recognitions speak for themselves.',
      'employer_branding.cta_1.text': 'Work with us',
      'employer_branding.cta_1.url': 'https://karriere.paraplegie.ch/',
      'employer_branding.cta_2.text': 'Vacancies',
      'employer_branding.cta_2.url':
        'https://karriere.paraplegie.ch/de/was-uns-auszeichnet',
      'employer_branding.badges.one.img': '/img/badges/fws_en.png',
      'employer_branding.badges.one.alt': '',
      'employer_branding.badges.two.img': '/img/badges/safa_fr.png',
      'employer_branding.badges.two.alt':
        'Top Arbeitgeber 2024 - Kategorie 1000+ Mitarbeitenden',
    },
  },
  it: {
    urls: {
      search: '/it/search',
      newsList: '/it/news',
      newsListAll: '/it/news/all',
      eventList: '/it/events',
      eventListAll: '/it/events/all',
      blogList: '/it/blog',
      blogListAll: '/it/blog/all',
      historyList: '/it/history',
      doctorList: '/it/doctors',
    },
    translation: {
      '{{bytes}} Bytes': '{{bytes}} bytes',
      '{{bytes}} GB': '{{bytes}} GB',
      '{{bytes}} KB': '{{bytes}} KB',
      '{{bytes}} MB': '{{bytes}} MB',
      '{{capacity}} cm<sup>3</sup>': '{{capacity}} cm<sup>3</sup>',
      '{{km}} km': '{{km}} km',
      '{{price}} CHF': '{{price}} CHF',
      '{{section}} Subnavigation': '{{section}} Sottomenù',
      '1st Circulation': '1° messa in circolazione',
      '404 – Not found': 'Errore 404 - pagina non trovata',
      'Page not found': 'Pagina non trovata',
      'About us': 'Chi siamo',
      'ac_paraplegie_ch.name': 'Active Communication',
      'ac_paraplegie_ch.text':
        'Noi riforniamo le persone con disabilità o malattie neurologiche con mezzi ausiliari elettronici e didattici ai fini di una maggiore partecipazione e autodeterminazione nella loro vita quotidiana.',
      'ac_paraplegie_ch.title': 'Active Communication',
      'Additional equipment': 'Equipaggiamento addizionale',
      All: 'Tutti',
      'All companies from A-Z': 'Tutte le aziende A-Z',
      'All posts': 'Tutti gli articoli',
      April: 'Aprile',
      Arrival: 'Arrivo',
      August: 'Agosto',
      Back: 'Indietro',
      'Back to {{domain}} Home':
        'Ritornare alla pagina di accoglienza {{domain}}',
      'Back to the frontpage.': 'Ritornare alla pagina d’accoglienza',
      'Become a member': 'Diventare membro',
      'Mitglied werden': 'Diventare membro',
      Blog: 'Blog',
      'Book a room': 'Prenotare una camera',
      Brand: 'Marca',
      Capacity: 'Cilindrata',
      Categories: 'Categorie',
      Category: 'Categoria',
      'Check availability': 'Verifica disponibilità',
      CHF: 'CHF',
      'Choose donation amount': 'Selezionare importo donazione',
      'Click here for the homepage.':
        'Cliccare qui per accedere alla pagina d’accoglienza.',
      'Close "{{section}}" Flyout': 'Chiudi il menù a discesa "{{section}}"',
      'Close Photogallery (ESC)': 'Chiudere la galleria (ESC)',
      'Close search': 'Chiudere la finestra di ricerca',
      Color: 'Colore',
      'Companies by industries': 'Aziende per ordine di settore',
      'Companies by industry': 'Aziende per ordine di settore',
      'Company name, place or industry': 'Aziende, Luogo o settore',
      Competences: 'Competenze',
      Contact: 'Contatto',
      'Contact {{domain}}': 'Contattare {{domain}}',
      'Current weather': 'Il tempo di oggi',
      'Custom amount': 'Altro importo',
      December: 'Dicembre',
      Delete: 'Eliminare',
      Departure: 'Partenza',
      Details: 'Dettagli',
      'Doctors at the SPC': 'I medici nel CSP',
      Donate: 'Donazioni',
      'Donate now': 'Dona ora',
      'Donate now - short': 'Dona ora',
      'Download document': 'Scaricare il documento',
      'Each <em>Donation</em> counts': 'Ogni <em>donazione</em> conta',
      Edit: 'Modificare',
      Email: 'Email',
      Mail: 'Mail',
      'Main Treatment Areas': 'Specialità cliniche',
      'Event info': 'Informazioni sull’evento',
      Events: 'Eventi',
      Fax: 'Telefax',
      February: 'Febbraio',
      Field: 'Area specialistica',
      'Filter by organisation': 'Filtrare per organizzazione',
      'Follow Link': 'Visualizzare hyperlink',
      Footer: 'Piè di pagina',
      'forschung_paraplegie_ch.name': 'Ricerca svizzera per paraplegici',
      'forschung_paraplegie_ch.text':
        'Quale centro di competenza e conoscenza, siamo punto d’appoggio per la realizzazione e lo sviluppo di mezzi ausiliari per le persone con lesione midollare.',
      'forschung_paraplegie_ch.title': 'Ricerca svizzera per paraplegici',
      'fst_paraplegie_ch.name': 'Centro d’innovazione per tecnologie assistive',
      'fst_paraplegie_ch.text':
        'Quale centro di competenza e conoscenza, siamo punto d’appoggio per la realizzazione e lo sviluppo di mezzi ausiliari per le persone con lesione midollare.',
      'fst_paraplegie_ch.title':
        'Centro d’innovazione per tecnologie assistive',
      Function: 'Funzione',
      Glossary: 'Ordine alfabetico',
      'gonner_vereinigung_paraplegie_ch.name': 'Unione dei sostenitori',
      'gonner_vereinigung_paraplegie_ch.text':
        'La solidarietà di oltre 1,8 milioni di membri dell’Unione dei sostenitori della Fondazione svizzera per paraplegici ci consente di mantenere la rete di prestazioni integrale a favore delle persone para e tetraplegiche.',
      'gonner_vereinigung_paraplegie_ch.title': 'Unione dei sostenitori',
      'Head of Departments': 'Primari',
      History: 'Storia',
      'History overview': 'Panoramica della storia',
      Home: 'Ritornare alla pagina di accoglienza',
      Inside: 'Abitacolo',
      'Is current page': 'Pagina attuale',
      January: 'Gennaio',
      July: 'Luglio',
      June: 'Giugno',
      KM: 'Km',
      Language: 'Lingua',
      'Latest news': 'Attualità',
      'Latest news overview': 'Panoramica degli articoli attuali',
      'Learn more': 'Di più',
      Links: 'Links',
      'Load more': 'Mostra di più',
      'Mail {{mail}}': 'Mail {{mail}}',
      'Mail: {{mail}}': 'Email: {{mail}}',
      March: 'Marzo',
      May: 'Maggio',
      'Medical domain': 'Area medica',
      Memberships: 'Membro delle seguenti società specialistiche',
      Menu: 'Menù',
      MFK: 'Collaudo',
      Mobile: 'Cellulare',
      Modification: 'Modifiche',
      Month: 'Mese',
      More: 'Di più',
      'More...': 'Di più…',
      'Navigation area': 'Area di navigazione',
      News: 'Attualità',
      Newsletter: 'Newsletter',
      'Newsletter Sign up': 'Iscrizione alla newsletter',
      Next: 'Avanti',
      'Next page': 'Prossima pagina',
      'Next years': 'Anni successivi',
      'No matter the amount, you make an important contribution to paraplegics.':
        'Indipendentemente dall’importo, ogni donazione è un contributo importante per le persone mielolese.',
      November: 'Novembre',
      October: 'Ottobre',
      'Oops, this page does not exist.<br />Would you like to help paraplegics?<br />Click on a heart.':
        'Ci scusiamo per il disagio, questa pagina non esiste.<br />Desiderate sostenere i para e tetraplegici?<br />Per farlo, cliccare su un cuore.',
      'Open image in fullscreen': "Visualizzare l'immagine a tutto schermo ",
      'Open navigation': 'Aprire la navigazione',
      'Open Search': 'Aprire la ricerca',
      Options: 'Accessori',
      Organisation: 'Organizzazione',
      Organisations: 'Organizzazioni',
      'orthotec_paraplegie_ch.name': 'Orthotec',
      'orthotec_paraplegie_ch.text':
        'Offriamo soluzioni personalizzate elaborate su misura per consentire la maggiore mobilità possibile alle persone para e tetraplegiche.',
      'orthotec_paraplegie_ch.title': 'Orthotec',
      'orthotecsports_paraplegie_ch.name': 'Orthotecsports',
      'orthotecsports_paraplegie_ch.title': 'Orthotecsports',
      Page: 'Pagina',
      'parahelp_paraplegie_ch.name': 'Parahelp',
      'parahelp_paraplegie_ch.text':
        'Consigliamo e assistiamo le persone con lesione midollare e i loro familiari a casa.',
      'parahelp_paraplegie_ch.title': 'ParaHelp',
      'Paraplegie.ch blog overview': 'Paraplegie.ch blog Panoramica',
      'Paraplegie.ch doctors overview': 'Paraplegie.ch Panoramica dei medici',
      'Paraplegie.ch events overview': 'Paraplegie.ch Panoramica eventi',
      'paraplegie_ch.name': 'Paraplegia Area principale',
      'paraplegie_ch.title': 'Gruppo Svizzero Paraplegici',
      Phone: 'Telefono',
      'Phone number: {{phone}}': 'Numero di telefono: {{phone}}',
      'Please input some keywords': 'Inserire un termine di ricerca.',
      'Please use other keywords for searching.':
        'Preghiamo di provare con un altro termine di ricerca.',
      Previous: 'Indietro',
      'Previous page': 'Pagina precedente',
      'Previous years': 'Anni precedenti',
      Price: 'Prezzo',
      'Price new': 'Prezzo originale',
      Print: 'Stampare',
      'Professional Career': 'Esperienze professionali',
      PS: 'CV',
      Publications: 'Pubblicazioni',
      Published: 'Pubblicato',
      Read: 'Leggere',
      'Read more': 'Per saperne di più',
      'Related posts': 'Altri articoli',
      'Request an offer': "Richiedere un'offerta ",
      'Scroll down': 'Scorrere verso il basso',
      Search: 'Ricercare',
      'Search company by name, industry or place':
        'Cerca azienda in base a nome, settore o luogo',
      'Search for <strong>{{term}}</strong>': 'Cerca <strong>{{term}}</strong>',
      'Search on Paraplegie.ch': 'Cercare su paraplegie.ch',
      'Search term': 'Termine di ricerca',
      Selected: 'Selezionato',
      'Senior Consultants': 'Medici caposervizio',
      September: 'Settembre',
      Services: 'Servizio',
      Share: 'Condividere',
      '--Show all--': '--Mostrare tutti--',
      'Show CV': 'Mostrare curriculum vitae',
      'Show next image': 'Mostrare la prossima immagine',
      'Show previous image': 'Mostrare l’ultima immagine',
      'sirmed_paraplegie_ch.name': 'Sirmed',
      'sirmed_paraplegie_ch.text':
        'La riabilitazione inizia sul luogo dell’infortunio. L’Istituto svizzero Medicina di Primo Soccorso Sirmed forma personale di primo intervento per il soccorso di pazienti infortunati',
      'sirmed_paraplegie_ch.title': 'Sirmed',
      'Slide {{slide}}': 'Slide {{slide}}',
      'Specialty Registrars': 'Medici capoclinica',
      Specialist: 'Medico specialisti',
      'Specialist title': 'Titolo di medico specializzato',
      'Specialist titles and specialisations':
        'Titoli specialistici e specializzazioni',
      'sportmedizin_paraplegie_ch.name': 'Medicina dello sport Nottwil',
      'sportmedizin_paraplegie_ch.text':
        'Con la nostra offerta globale di prestazioni medico-sportive saremo lieti di accompagnarla sul cammino verso il suo prossimo obiettivo sportivo.',
      'sportmedizin_paraplegie_ch.title': 'Medicina dello sport Nottwil',
      'stiftung_paraplegie_ch.name': 'Fondazione svizzera per paraplegici',
      'stiftung_paraplegie_ch.text': 'Noi assistiamo i paraplegici. A vita.',
      'stiftung_paraplegie_ch.title': 'Fondazione svizzera per paraplegici',
      Suche: 'Ricerca',
      'Support one of our heart projects for paraplegics':
        'uno dei nostri progetti del cuore a favore dei para e tetraplegici.',
      'Paraplegiker-Gruppe': 'Gruppo Paraplegici',
      'Swiss Paraplegic Group': 'Gruppo Svizzero Paraplegici',
      'Swiss Paraplegic Group on Facebook':
        'Fondazione per paraplegici su Social Media',
      'Telephone {{phone}}': 'Telephone {{phone}}',
      'There are no blog posts that match your filters.':
        'Nessun articolo è stato trovato con i criteri di ricerca selezionati.',
      'There are no companies that match your filters.':
        'Nessuna azienda corrisponde ai filtri impostati.',
      'There are no doctors that match your filters.':
        'Nessun medico è stato trovato con i criteri di ricerca selezionati.',
      'There are no events that match your filters.':
        'Nessun evento è stato trovato con i criteri di ricerca selezionati.',
      'There are no medical offers that match your filters.':
        'Nessuna prestazione medica corrispondente ai criteri di ricerca.',
      'There are no news that match your filters.':
        'Nessun articolo è stato trovato con i criteri di ricerca selezionati.',
      'To top': 'Verso l’alto',
      Toggle: 'Commutare',
      'Training and Education': 'Formazione professionale e continua',
      Translate: 'Tradurre',
      'Unfortunately we could not find any content for this address. Perhaps you followed an old or incorrect link. Please check the address again.':
        'Purtroppo questa pagina è introvabile. Forse il link seguito è fuori uso o scorretto. Per favore ricontrollare l’indirizzo.',
      'vereinigung_paraplegie_ch.name': 'Associazione svizzera dei paraplegici',
      'vereinigung_paraplegie_ch.text':
        'Quale organizzazione di autoaiuto e organizzazione specialistica per le persone con lesione midollare, ci impegniamo su tutto il territorio svizzero per le esigenze dei para e tetraplegici.',
      'vereinigung_paraplegie_ch.title':
        'Associazione svizzera dei paraplegici',
      Weather: 'Tempo',
      'Weather forecast for the next three days':
        'Purtroppo questa pagina è introvabile. Forse il link seguito è fuori uso o scorretto. Per favore ricontrollare l’indirizzo.',
      'Website {{website}}': 'Website {{website}}',
      Year: 'Anno',
      Tomorrow: 'Domani',
      Morgen: 'Domani',
      Demain: 'Domani',
      Domani: 'Domani',
      Monday: 'Lunedì',
      Montag: 'Lunedì',
      Lundi: 'Lunedì',
      Lunedi: 'Lunedì',
      Tuesday: 'Martedì',
      Wednesday: 'Mercoledì',
      Mittwoch: 'Mercoledì',
      Mercredi: 'Mercoledì',
      Mercoledi: 'Mercoledì',
      Thursday: 'Giovedì',
      Donnerstag: 'Giovedì',
      Jeudi: 'Giovedì',
      Giovedi: 'Giovedì',
      Friday: 'Venerdì',
      Freitag: 'Venerdì',
      Vendredi: 'Venerdì',
      Venerdi: 'Venerdì',
      Saturday: 'Sabato',
      Samstag: 'Sabato',
      Samedi: 'Sabato',
      Sabato: 'Sabato',
      Sunday: 'Domenica',
      Sonntag: 'Domenica',
      Dimanche: 'Domenica',
      Domenica: 'Domenica',
      'You are here': 'Vi trovate qui',
      'Your search returned <strong>{{results}} results</strong>.':
        'La ricerca ha prodotto <strong>{{results}} risultati</strong>.',
      'zentrum_paraplegie_ch.name': 'Centro svizzero per paraplegici',
      'zentrum_paraplegie_ch.text':
        'Quale clinica specialistica per para e tetraplegici ci impegniamo a favore della riabilitazione globale e dell‘assistenza medico-sanitaria completa delle persone con lesione midollare.',
      'zentrum_paraplegie_ch.title': 'Centro svizzero per paraplegici',
      Show: 'Visualizza',
      'Future events': 'Eventi futuri',
      'Past events': 'Eventi passati',
      'Overview of all news': 'Panoramica su tutte le novità',
      'Overview of all events': 'Panoramica su tutti gli eventi',
      'View on Google Maps': 'Visualizza su Google Maps',
      Login: 'Login',
      Username: 'Username',
      Password: 'Password',
      Submit: 'Submit',
      'Sign up for the Paraplegic Foundation newsletter':
        'Sign up for the Paraplegic Foundation newsletter',
      'Email address': 'Indirizzo e-mail',
      Subscribe: 'Abbonarsi',
      Gender: 'Gender',
      Male: 'Male',
      Female: 'Female',
      'Last name': 'Last name',
      'First name': 'First name',
      Interest: 'Interest',
      'Subscribe to our newsletter': 'Subscribe to our newsletter',
      'Total cost': 'Total cost',
      'Personal data': 'Personal data',
      Surname: 'Surname',
      Company: 'Company',
      Street: 'Street',
      'Street number': 'Street number',
      'P.O. Box': 'P.O. Box',
      Postcode: 'Postcode',
      'Town/City': 'Town/City',
      Country: 'Country',
      'Proceed to payment': 'Proceed to payment',
      'Your address': 'Your address',
      'Ms.': 'Ms.',
      'Mr.': 'Mr.',
      'I would like the Centre for Pain Medicine to keep me informed about conferences and courses.':
        'I would like the Centre for Pain Medicine to keep me informed about conferences and courses.',
      'I would like to receive a payment confirmation for my employer.':
        'I would like to receive a payment confirmation for my employer.',
      'Latest blogs': 'Ultimi post',
      Membership: 'Affiliazione',
      'Individual member': 'Membro singolo',
      'Married couples and families': 'Coniugi e famiglie',
      'Permanent member': 'Membro permanente',
      'Become a member now': 'Diventa membro ora',
      'per year': "all'anno",
      'One-time': 'Una tantum',
      'Contact us': 'Pregasi contattarci',
      'Quick Links': 'Link veloci',
      'Main navigation': 'Navigazione principale',
      'Member Service': 'Servizio sostenitori',
      'A part of': 'Una parte di',
      'We offer': 'Offriamo',
      'Job Type': 'Job Type',
      'Donations for people with spinal cord injuries':
        'Donare per le persone paraplegiche',
      'Your transaction is secure': 'La vostra transazione è sicura',
      'Each Donation counts': 'Ogni donazione conta',
      '<em>Become</em> a member': 'Diventare <em>membro</em>',
      'And receive 250,000 francs in an emergency.':
        'I membri ricevono un sussidio di 250 000 franchi nel caso subiscano una para o tetraplegia conseguente a infortunio.',
      'Couples and families': 'Coppie e famiglie',
      once: 'una tantum',
      'CHF 250,000 if the unthinkable happens':
        '250 000 franchi in caso d’emergenza',
      'Protect yourself - become a member now': 'Aderire ora e premunirsi',
      '1.9 million members have already put their trust in the Swiss Paraplegic Foundation.':
        'Già 2 milioni di membri hanno fiducia nella Fondazione svizzera per paraplegici.',
      '<em>Give the gift</em> of membership':
        '<em>Regalare</em> un’affiliazione',
      'Your loved ones will receive 250,000 francs should the unthinkable happen.':
        'In caso d’emergenza i vostri cari ricevono 250 000 franchi.',
      'Gift now': 'Regalare ora',
      'A useful gift for your loved ones': 'Un regalo utile per i vostri cari',
      'Giving a membership will provide joy and show you care.':
        'Sottoscrivendo un’affiliazione fate un bel regalo e al contempo fate prevenzione.',
      'blocks.footer_contact': `
        <div class="FooterAddress">
          <div class="vcard">Centro svizzero per paraplegici<br />
              Guido A. Zäch Strasse 1<br />
              6207 Nottwil<br />
              <a href="mailto:spz@paraplegie.ch">spz@paraplegie.ch</a><br />
              <a href="tel:+41419395454">T.&nbsp;+41 41 939 54 54</a>
          </div>
          <br />
          <div class="vcard">Fondazione svizzera per paraplegici<br />
            Unione dei sostenitori<br />
            Guido A. Zäch Strasse 6<br />
            6207 Nottwil<br />
            <a href="mailto:sps@paraplegie.ch">sps@paraplegie.ch</a><br />
            <a href="tel:+41419396262">T.&nbsp;+41 41 939 62 62</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.sportmedizin_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Medicina dello Sport Nottwil<br />
            Guido A. Zäch Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:sportmedizin@sportmedizin-nottwil.ch">sportmedizin@sportmedizin-nottwil.ch</a><br />
            <a href="tel:+41419396600">T.&nbsp;+41 41 939 66 00</a>
          </div>
          <br />
          <div class="vcard">
            <b>Registrazione</b><br />
            Dal lunedì al giovedi<br />
            08.00 - 12.30 et 13.30 - 17.00 Uhr<br />
            venerdì<br />
            08.00 - 12.30 et 13.30 - 16.00 Uhr
          </div>
        </div>
      `,
      'blocks.footer_contact.sirmed_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">SIRMED<br />
            Istituto Svizzero Medicina di Primo Soccorso<br />
            Guido A. Zäch Strasse 2b<br />
            6207 Nottwil<br />
            <a href="mailto:info@sirmed.ch">info@sirmed.ch</a><br />
            <a href="tel:+41419395050">T.&nbsp;+41 41 939 50 50</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.activecommunication_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Active Communication<<br />
            Sumpfstrasse 28<br />
            6312 Steinhausen<br />
            <a href="mailto:info@activecommunication">office@activecommunication.ch</a><br />
            <a href="tel:+41417470303">T.&nbsp;+41 41 747 03 03</a><br />
            <a href="tel:+41417470300">T.&nbsp;+41 41 747 03 00 (Service-Center D)</a><br />
            <a href="tel:+41417470312">T.&nbsp;+41 41 747 03 12 (Service-Center F)</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.gonner_vereinigung_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Unione dei sostenitori della fondazione svizzera per paraplegici<br />
            Guido A. Zäch Strasse 10<br />
            6207 Nottwil<br />
            <a href="mailto:sps@paraplegie.ch">sps@paraplegie.ch</a><br />
            <a href="tel:+41419396262">T.&nbsp;+41 41 939 62 62</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.forschung_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Ricerca svizzera per paraplegici><br />
            Guido A. Zäch-Strasse 4<br />
            6207 Nottwil<br />
            <a href="mailto:spf@paraplegie.ch">spf@paraplegie.ch</a><br />
            <a href="tel:+41419396565">T.&nbsp;+41 41 939 65 65</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.orthotec_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Orthotec AG<br />
            Guido A. Zäch Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:info@orthotec.ch">info@orthotec.ch</a><br />
            <a href="tel:+41419395606">T.&nbsp;+41 41 939 56 06</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.orthotecsports_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Orthotec AG<br />
            Guido A. Zäch Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:info@orthotec.ch">info@orthotec.ch</a><br />
            <a href="tel:+41419395606">T.&nbsp;+41 41 939 56 06</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.stiftung_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Fondazione svizzera per paraplegici<br />
            Guido A. Zäch Strasse 10<br />
            6207 Nottwil<br />
            <a href="mailto:sps.sec@paraplegie.ch">sps.sec@paraplegie.ch</a>
            <a href="tel:+41419396363">T.&nbsp;+41 41 939 63 63</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.zentrum_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">Centro svizzero per paraplegici<br />
            Guido A. Zäch-Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:spz@paraplegie.ch">spz@paraplegie.ch</a><br />
            <a href="tel:+41419395454">T.&nbsp;+41 41 939 54 54</a>
          </div>
        </div>
      `,
      'blocks.footer_contact.parahelp_paraplegie_ch': `
        <div class="FooterAddress">
          <div class="vcard">ParaHelp AG<br />
            Guido A. Zäch-Strasse 1<br />
            6207 Nottwil<br />
            <a href="mailto:info@parahelp.ch">info@parahelp.ch</a><br />
            <a href="tel:+41419396060">T.&nbsp;+41 41 939 60 60</a>
          </div>
        </div>
      `,
      'blocks.content_footer.become_member': `
        <p>
          <strong>Aderite ora </strong> e ricevete <strong><span>250 000 franchi</span></strong> se dovesse accadere l’impensabile.
        </p>
      `,
      'blocks.content_footer.donate': `
        <p>
          <strong>Dona </strong>ora per sostenere i nostri progetti a favore delle <strong>persone para e tetraplegiche</strong>.
        </p>
      `,
      'footer.socialMenuTitle': `Fondazione svizzera per paraplegici su social media`,
      'footer_organisation.socialMenuTitleSubtext': `su social media`,
      'newsroom.teaser.view-all': 'Mostrare tutto',
      'Show all': 'Mostrare tutto',
      'newsroom.empty': 'Non ci sono contenuti che corrispondono ai filtri.',
      'newsroom.teaser.headline': 'Stories and News',
      'newsroom.teaser.subheadline': 'Our Articles in Focus',
      'cta.button_type.donate': 'Donazioni',
      'cta.button_type.membership': 'Diventare membro',
      'cta.button_type.gift': 'Regalare ora',
      'employer_branding.title':
        'Make the difference at the Swiss Paraplegic Group',
      'employer_branding.description':
        'Working in a globally unique service network and making a meaningful contribution every day. Our recognitions speak for themselves.',
      'employer_branding.cta_1.text': 'Work with us',
      'employer_branding.cta_1.url': 'https://karriere.paraplegie.ch/',
      'employer_branding.cta_2.text': 'Vacancies',
      'employer_branding.cta_2.url':
        'https://karriere.paraplegie.ch/de/was-uns-auszeichnet',
      'employer_branding.badges.one.img': '/img/badges/fws_en.png',
      'employer_branding.badges.one.alt': '',
      'employer_branding.badges.two.img': '/img/badges/safa_fr.png',
      'employer_branding.badges.two.alt':
        'Top Arbeitgeber 2024 - Kategorie 1000+ Mitarbeitenden',
    },
  },
};
